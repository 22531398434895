import { AuthenticateStatus, productManagerEnv, ProductManagerEnv, ProductManagerEnvItem } from '@/config';
import { Product, Seller } from '@/models';
import { useProcurementStore, useUserData } from '@/zustand';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { formatNumber } from 'src/logic/utils/numbers';
import { AddProductToEnvironmentButton, ProductEditPrice } from 'src/ui/components/dumb';
import LoadingLayer from 'src/ui/components/dumb/shared/loading-layer';
import SignupRequiredPopup from 'src/ui/components/dumb/shared/signup-required-popup';
import { TextFieldCounter } from 'src/ui/components/shared';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import CustomTooltip from './custom-tooltip';
import { sm } from 'src/site-map';

interface props {
  environment: ProductManagerEnvItem;
  product: Product;
  sellers: Seller[];
  hasActions: boolean;
  hasQuoteActions: boolean;
  isAlternative: boolean;
}

export const ProductSuppliers: FC<props> = ({
  environment,
  product,
  sellers,
  hasActions,
  hasQuoteActions,
  isAlternative,
}) => {
  const loginPopup = usePopup();

  const { companyInfo, isSeller } = useUserData();
  const mySellerId = companyInfo?.id;
  const showOnlyMe = isSeller();
  const isGuest = useUserData().authenticationStatus !== AuthenticateStatus.AUTHENTICATED;

  const { changeProductDetailsMainProductQuantity, changeProductDetailsMainProductPrice } = useProcurementStore();
  const { formDetails } = useProcurementStore(state => state.productFormManagerProperties.data);
  const { data } = useProcurementStore(state => state.productFormManagerProperties);
  const insideAddingToFormEnv = data?.environment != null;

  const { changeProductQuantity, changeProductAlternativesProductQuantity } = useProcurementStore();
  const { changeAlternativesDetailsPrice, changeProductPrice } = useProcurementStore();

  const handleChangeProductQuantity = (product: Product, newQuantity: number, sellerId: string) => {
    changeProductDetailsMainProductQuantity(newQuantity, insideAddingToFormEnv ? '' : sellerId);
    changeProductQuantity(newQuantity, product);
    if (isAlternative) {
      changeProductAlternativesProductQuantity(product, newQuantity);
    } else {
      changeProductQuantity(newQuantity, product);
    }
  };

  const handleChangeProductPrice = (product: Product, newPrice: string, sellerId: string) => {
    changeProductDetailsMainProductPrice(newPrice, insideAddingToFormEnv ? '' : sellerId);
    if (isAlternative) {
      changeAlternativesDetailsPrice(product, newPrice);
    } else {
      changeProductPrice(product, newPrice);
    }
  };

  const loadingProductsIds = data.loadingProductsIds;
  const product_id = product.id;
  const isProductLoading = React.useMemo(
    () => loadingProductsIds.find(prod => prod === product_id) !== undefined,
    [product_id, loadingProductsIds]
  );

  return (
    <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
      {isProductLoading &&
        ![ProductManagerEnv.requisition, ProductManagerEnv.catalog].includes(data.environment?.type) && (
          <LoadingLayer />
        )}
      {sellers?.map((el, index) => {
        if (showOnlyMe && Number(el.seller.id) !== Number(mySellerId)) {
          return null;
        }

        const valuesToBeShown = insideAddingToFormEnv ? { ...product } : { ...el };
        // const handleChangePrice = (value: string) => changeProductDetailsMainProductPrice(value, insideAddingToFormEnv ? undefined : el.seller.id)
        // const handleChangeQuantity = (value: string) => changeProductDetailsMainProductQuantity(value, insideAddingToFormEnv ? undefined : el.seller.id)
        return (
          <Grid
            container
            alignItems={'center'}
            sx={{ width: '100%', backgroundColor: 'red', mb: 1, bgcolor: 'secondary.contrastText', borderRadius: 2 }}
          >
            {/* {index != 0 && <Box width={"100%"}>
              <Divider sx={{ borderColor: 'info.contrastText' }} /></Box>
            } */}
            <Grid container md={5} p={2} px={2}>
              <Grid item container md={7} mr={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <Typography
                    variant='body2'
                    sx={{
                      color: 'text.disabled',
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {el.seller.name}
                  </Typography>
                  {el.is_private && (
                    <Box sx={{ flexShrink: 0, ml: 1 }}>
                      <PrivacyButton
                        disableText
                        content={'This seller profile is private because it was created and/or linked by your company.'}
                        zIndex={2}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>

              {el.unit_price ? (
                <Grid item container md={4}>
                  <Typography variant='body2' sx={{ color: 'text.third', fontWeight: 500 }}>
                    {t('texts.unit_price')}:{' '}
                    <strong style={{ color: '#3A3E50' }}>{formatNumber(el.unit_price)}AED</strong>
                  </Typography>
                </Grid>
              ) : (
                <Grid item md={4}>
                  <Typography variant='body2' sx={{ color: 'text.third', fontWeight: 500 }}>
                    No Listed Price
                  </Typography>
                </Grid>
              )}
            </Grid>
            {isGuest && !environment && (
              <Grid item container md={6.7} p={2} gap={1} justifyContent='flex-end'>
                <Grid item md={3} pr={1}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }} width='100%'>
                    <TextFieldCounter
                      value={valuesToBeShown.quantity}
                      changeQuantity={newQuantity => handleChangeProductQuantity(product, newQuantity, el.seller.id)}
                    />
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <ProductEditPrice
                    handleChangePrice={newPrice => handleChangeProductPrice(product, newPrice, el.seller.id)}
                    value={valuesToBeShown.price}
                    showEndAdornment
                  />
                </Grid>
                <Grid item md={3}>
                  <Button type='button' color='primary' variant='contained' onClick={loginPopup.handleOpen} fullWidth>
                    Add to PO
                  </Button>
                </Grid>
              </Grid>
            )}
            {((formDetails?.seller_details?.id == el.seller.id &&
              (environment?.type === ProductManagerEnv.purchase_order ||
                environment?.type === ProductManagerEnv.edit_po_from_scratch)) ||
              !environment) &&
              hasActions && (
                <Grid item container md={6.7} p={2} gap={1} justifyContent='flex-end'>
                  <Grid item md={3} pr={1}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} width='100%'>
                      <TextFieldCounter
                        value={valuesToBeShown.quantity}
                        changeQuantity={newQuantity => handleChangeProductQuantity(product, newQuantity, el.seller.id)}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <ProductEditPrice
                      handleChangePrice={newPrice => handleChangeProductPrice(product, newPrice, el.seller.id)}
                      value={valuesToBeShown.price}
                      showEndAdornment
                    />
                  </Grid>
                  <Grid item md={3}>
                    <AddProductToEnvironmentButton
                      product={product}
                      titleForAddedProduct='added_to_po'
                      environment={environment?.type || productManagerEnv.purchase_order}
                      titleForAddingProduct={t('buttons.add_to_PO')}
                      seller={el}
                    />
                  </Grid>
                </Grid>
              )}
            {(environment?.type === ProductManagerEnv.quote ||
              environment?.type === ProductManagerEnv.edit_quote_from_scratch) &&
              hasQuoteActions && (
                <Grid item container md={6.7} p={2} gap={1} justifyContent='flex-end'>
                  <Grid item md={3} pr={1}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} width='100%'>
                      <TextFieldCounter
                        value={valuesToBeShown.quantity}
                        changeQuantity={newQuantity => handleChangeProductQuantity(product, newQuantity, el.seller.id)}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <ProductEditPrice
                      handleChangePrice={newPrice => handleChangeProductPrice(product, newPrice, el.seller.id)}
                      value={valuesToBeShown.price}
                      showEndAdornment
                    />
                  </Grid>
                  <Grid item md={3}>
                    <AddProductToEnvironmentButton
                      product={product}
                      titleForAddedProduct='added_to_Quote'
                      environment={environment?.type}
                      titleForAddingProduct={t('buttons.add_to_quote')}
                      seller={el}
                    />
                  </Grid>
                </Grid>
              )}
            <Grid item md={0.3} sx={{ display: 'flex', justifyContent: 'end', marginInlineStart: 'auto', px: 1 }}>
              <ActionsMenu seller={el} productId={product_id} />
            </Grid>
          </Grid>
        );
      })}
      {/* <LoginPopup loginPopup={loginPopup} refreshOnLogin /> */}
      <SignupRequiredPopup open={loginPopup.isOpen} handleClose={loginPopup.handleClose} signupUrl={sm.register.asBuyer.index.url} />
    </Grid>
  );
};

const ActionsMenu = ({ seller, productId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { unlinkSeller } = useProcurementStore();

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleUnlinkSeller = () => {
    unlinkSeller(seller.seller.id, productId);
  };

  return (
    <Grid item md={0.3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      {seller.is_private && (
        <>
          <IconButton onClick={handleOpenMenu}>
            <MoreVertIcon sx={{ color: 'primary.main' }} />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuItem onClick={handleCloseMenu}>
              {seller.can_buyer_unlink ? (
                <Button
                  onClick={handleUnlinkSeller}
                  startIcon={<LinkOffIcon sx={{ color: '#FF4D4D' }} />}
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    color: '#FF4D4D',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Unlink Seller
                </Button>
              ) : (
                <CustomTooltip title='Private Seller cannot be unlinked because it is part of an active form.' arrow>
                  <span>
                    <Button
                      onClick={null}
                      disabled
                      startIcon={<LinkOffIcon />}
                      sx={{
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#FF4D4D',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                        },
                      }}
                    >
                      Unlink Seller
                    </Button>
                  </span>
                </CustomTooltip>
              )}
            </MenuItem>
          </Menu>
        </>
      )}
    </Grid>
  );
};
