import { request } from '@/helpers';
import { ProcurementFormModel } from '@/models';
import { useInfiniteQuery } from '@tanstack/react-query';
import { PaginatedResponse } from 'src/logic/models/queries';

export const useGetQuotesRelatedToProducts = ({ product_id, search }: { product_id?: number; search?: string }) => {
  return useInfiniteQuery<PaginatedResponse<ProcurementFormModel>>({
    queryKey: ['QUOTES_RELATED_TO_PRODUCT', { product_id, search }],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await request({
        url: '/seller/quote/add-product-data',
        method: 'GET',
        params: {
          page: pageParam,
          product_id,
          query: search,
        },
      });
      return response.data;
    },
    retry: 0,
    refetchOnMount: true,
    getNextPageParam: lastPage => {
      const { current_page, last_page } = lastPage.meta;
      if (current_page === last_page) {
        return undefined;
      }
      return current_page + 1;
    },
    enabled: !!product_id,
  });
};

export const useGetCompaniesRelatedToProducts = ({ product_id, search }: { product_id?: number; search?: string }) => {
  return useInfiniteQuery<PaginatedResponse<ProcurementFormModel>>({
    queryKey: ['COMPANIES_RELATED_TO_PRODUCT', { product_id, search }],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await request({
        url: '/seller/quote/add-product-companies-data',
        method: 'GET',
        params: {
          page: pageParam,
          product_id,
          query: search,
        },
      });
      return response.data;
    },
    retry: 0,
    refetchOnMount: true,
    getNextPageParam: lastPage => {
      const { current_page, last_page } = lastPage.meta;
      if (current_page === last_page) {
        return undefined;
      }
      return current_page + 1;
    },
    enabled: !!product_id,
  });
};
