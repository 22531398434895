import { Link, Loading } from '@/components';
import { getInputsTranslationKey } from '@/hooks';
import { DeleteIconSmall } from '@/icons';
import { ColumnType, FilterKind, LinkType, ReadStatus, TableFilters, TableMapperType } from '@/models';
import { useUserData } from '@/zustand';
import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Switch, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import useSetHeaderData from 'src/logic/hooks/use-header';
import { getUrlBasedOnFormType } from 'src/logic/services/form-types-navigation';
import { SkeletonFormType } from 'src/logic/services/query-handlers/QueryStatusComponent';
import { SkeletonForm } from 'src/logic/services/query-handlers/skeleton-form.tsx/skeleton-form';
import { sm } from 'src/site-map';
import { EditableTextField, ExpandedSearchIcon, StyledPagination, StyledPopper, StyledSelect } from 'src/ui/components/shared';
import ErrorWithRefetchButton from 'src/ui/components/shared/error-with-refetch';
import { StatusChip } from '../../procurement';
import { CheckBoxAction } from './checkbox-action';
import { StatusChild } from './child-status-table';
import { FilterByDateType } from './date-filter';
import { FilterByCheckboxMenuType } from './multi-checkbox-filter';
import { PriceRangeFilter } from './price-range-filter';
import { ProcurementTableData } from './table-data';

export type TablesFormProps = {
    showTableHeader?: boolean;
    tableSliceProperties: any
    tableName?: string
    tableType?: TableMapperType
    canCreate?: boolean
    isCreateLoading?: boolean
    createButtonName?: string
    headerButton?: string | undefined
    onCreateAction?: (() => void) | undefined
    handleChangeInput?: (id: string, value: string) => void
    handleChangeSelect?: (id: string, value: string) => void
    handleSwitch?: (id: string, value: boolean) => void
    handleDelete?: (id: string) => void
    handleButtonAction?: (id: string) => void
    onClickHeaderButton?: (() => void) | undefined
};

export const TablesForm: React.FC<TablesFormProps> = ({ tableSliceProperties, tableType, tableName, canCreate, createButtonName, onCreateAction, isCreateLoading, handleChangeInput, handleChangeSelect, handleSwitch, handleButtonAction, handleDelete, showTableHeader = true, headerButton, onClickHeaderButton }) => {

    if (tableSliceProperties.data.isError) {
        return (
            <ErrorWithRefetchButton
                text={tableSliceProperties.data.errorMessage}
                onRefetch={() => tableSliceProperties.actions.loadData(tableType)}
            />
        );
    }

    if (tableSliceProperties.data.isLoading) {
        useSetHeaderData(tableName, null);
        return <SkeletonForm type={SkeletonFormType.TABLE} />;
    }

    const total = tableSliceProperties.data.tablePaginationResult?.total;

    useSetHeaderData(tableName, total);

    return (
        <Grid container md={12}>
            {
                tableSliceProperties.data.isLoading ?
                    <Loading fullHeight={true} /> :
                    <>
                        {showTableHeader && <ShowTableHeader isCreateLoading={isCreateLoading} tableSliceProperties={tableSliceProperties} tableType={tableType} tableName={tableName} canCreate={canCreate} createButtonName={createButtonName} onCreateAction={onCreateAction} headerButton={headerButton} onClickHeaderButton={onClickHeaderButton} />}
                        <ShowTableFilters tableSliceProperties={tableSliceProperties} tableType={tableType} />
                        <ShowTableData tableSliceProperties={tableSliceProperties} handleChangeSelect={handleChangeSelect} handleChangeInput={handleChangeInput} handleSwitch={handleSwitch} handleButtonAction={handleButtonAction} handleDelete={handleDelete} tableType={tableType} />
                    </>
            }
        </Grid >
    )
}


export const ShowTableHeader: React.FC<TablesFormProps> = ({ isCreateLoading, tableSliceProperties, tableName, canCreate, createButtonName, onCreateAction, headerButton, onClickHeaderButton }) => {
    const storeValue = (searchValue: string) => { tableSliceProperties.actions.setSearchText(searchValue); };

    return (
        <Grid container justifyContent='space-between' alignItems="center" md={12} sx={{ borderRadius: '8px', mt: 3 }}>
            <Grid item>
                {/* <Typography variant="overline" fontWeight={700}>
                    {tableName}
                    {total !== undefined && total !== null && <PrimaryBadge>{total}</PrimaryBadge>}
                </Typography> */}
            </Grid>

            <Grid item gap={1} display='flex'>
                <ExpandedSearchIcon onChangeSearchText={storeValue} />
                {headerButton && <Button variant="outlined" fullWidth onClick={() => headerButton && onClickHeaderButton && onClickHeaderButton()} >{headerButton} </Button>}
                {canCreate && <div style={{ minWidth: '200px' }}> <LoadingButton loading={isCreateLoading} fullWidth variant="contained" startIcon={<AddIcon />} onClick={() => onCreateAction && onCreateAction()}>  {createButtonName} </LoadingButton></div>}
                {/* <DebouncedSearchInput onChange={storeValue} translateKey={"search_with_dots"} /> */}
            </Grid>
        </Grid>
    )
}


const NO_REQUIRES_MY_ACTIONS_NEEDED = [
    // TableMapperType.requisitions,
    TableMapperType.buyer_comparison_table,
    TableMapperType.projects_buyer,
    TableMapperType.projects_seller,
    TableMapperType.buyer_sellers,
    TableMapperType.seller_buyers,
    TableMapperType.catalog_draft,
    TableMapperType.seller_all_buyers,
    TableMapperType.buyer_all_sellers
];

const QUERY_FILTERS_TABLE_TYPES = [
    TableMapperType.quotes_buyer,
    TableMapperType.requisitions,
    TableMapperType.request_for_quotes_buyer,
    TableMapperType.buyer_comparison_table,
    TableMapperType.buyer_purchase_order,
    TableMapperType.request_for_quotes_seller,
    TableMapperType.seller_purchase_order,
    TableMapperType.quotes_seller,
]

export const ShowTableFilters: React.FC<TablesFormProps> = ({ tableSliceProperties, tableType }) => {
    const router = useRouter();

    const onFilterChanged = (type: string, kind: string, value: any) => {
        tableSliceProperties.actions.onFilterChanged(type, kind, value, (updatedFilters: any) => {
            if (updatedFilters && QUERY_FILTERS_TABLE_TYPES.includes(tableType)) {
                const encodedQueryParam = encodeURIComponent(JSON.stringify({ filters: updatedFilters }));
                router.replace(`${router.asPath.split('?')[0]}?filters=${encodedQueryParam}`)
            }
        })
    }

    const handleClearFilters = () => {
        tableSliceProperties.actions.clearFilters(tableType);
        router.replace(`${router.asPath.split('?')[0]}`)
    }

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(true);
    const toggleCollapse = () => setIsOpen(!isOpen)
    const showRequiredMyActions = React.useMemo(() => {
        if (tableType)
            return !NO_REQUIRES_MY_ACTIONS_NEEDED.includes(tableType)
        return false;
    }, [tableType]);

    return (
        <Grid container md={12} py={3} pl={1}>
            <Grid item md={2}>
                {isOpen ? (<Typography pt={1} color="primary.main" onClick={toggleCollapse} variant="h6" fontWeight={700} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}  >   {t('buttons.filters')}  {tableSliceProperties.data.selectedFiltersNumber !== 0 && (<>   {'(' + tableSliceProperties.data.selectedFiltersNumber + ')'}  </>)} <ExpandLessIcon sx={{ mr: 1, color: 'primary.main' }} />  </Typography>) : (<Typography pt={1} onClick={toggleCollapse} color="primary.main" variant="h6" fontWeight={700} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >   {t('buttons.filters')}   <ExpandMoreIcon sx={{ mr: 1, color: 'primary.main' }} />  </Typography>)}
                {isOpen ? (<Grid item container pt={2}>{tableSliceProperties.data.selectedFiltersNumber != 0 ? <Typography color={'error'} onClick={handleClearFilters} variant="h6" fontWeight={500} sx={{ cursor: 'pointer' }}>{t('buttons.clear_all')}</Typography> : <Grid item container pt={2}></Grid>}</Grid>) : <></>}
                {isOpen ? (<Grid item container pt={2}>{showRequiredMyActions && <CheckBoxAction value={tableSliceProperties.data.requiresMyAction} onChange={tableSliceProperties.actions.setRequiresMyAction} />} </Grid>) : <></>}
            </Grid>

            <Grid item md={10}>
                {isOpen ? <Grid container md={12} alignItems={'center'} gap={2} justifyContent={'space-between'}>
                    {tableSliceProperties.data.tableFilters?.map((filter: TableFilters) => (
                        <React.Fragment key={filter.type}>
                            {filter.kind == FilterKind.MULTI_SELECT && (<Grid item md={3}>
                                <FilterByCheckboxMenuType label={filter.title} type={filter.type} kind={filter.kind} filterItems={filter.value} onFilterChanged={onFilterChanged} />
                            </Grid>)}
                            {filter.kind == FilterKind.DATE && (
                                <Grid item md={3}>
                                    <FilterByDateType label={filter.title} type={filter.type} kind={filter.kind} onFilterChanged={onFilterChanged} />
                                </Grid>
                            )}
                            {filter.kind == FilterKind.MULTI_SELECT_STATUS && (
                                <Grid item md={3}>
                                    <FilterByCheckboxMenuType label={filter.title} type={filter.type} kind={filter.kind} filterItems={filter.value} onFilterChanged={onFilterChanged} isStatus />
                                </Grid>
                            )}
                            {filter.kind == FilterKind.RANGE && (
                                <Grid item md={3}>
                                    <PriceRangeFilter label={"Price Range"} type={filter.type} min={filter.initialValue?.min ?? 0} max={filter.initialValue?.max ?? 0} kind={filter.kind} onFilterChanged={onFilterChanged} onClear={tableSliceProperties.actions.clearFilterByKind} />
                                </Grid>
                            )}
                        </React.Fragment>
                    ))}
                </Grid>
                    : <></>
                }
            </Grid>
        </Grid >
    )
}




export const ShowTableData: React.FC<TablesFormProps> = ({ tableSliceProperties, handleChangeInput, handleSwitch, handleDelete, handleButtonAction, tableType }) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => { setExpanded(isExpanded ? panel : false); };
    const { t } = useTranslation();
    const { canUpdateCatalog } = useUserData();
    const { isSeller } = useUserData();
    const getTheURL = (type: string, id: string, queryId?: string) => {
        const url = getUrlBasedOnFormType(type as LinkType, id);
        if (type === LinkType.RFQ_OF_BUNDLE) {
            return { pathname: url, query: { rfqId: queryId } }
        }
        else if (type === LinkType.PO_OF_BUNDLE) {
            return { pathname: url, query: { 'purchase-order-id': queryId } }
        }
        return url;
    };

    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [expiryNote, setExpiryNote] = useState(isSeller() ? t('popups.subTitles.buyer_expiry_popup') : t('popups.subTitles.seller_expiry_popup'));

    const handleHoverIn = (event: React.MouseEvent<HTMLElement>, type: string) => {
        setIsHovered(true);
        setAnchorEl(event.currentTarget);
        if (type === LinkType.RFQ_SELLER || type === LinkType.QUOTE_BUYER || type === LinkType.PO_SELLER) {
            const newExpiryNote = t('popups.subTitles.expiry_popup');
            setExpiryNote(newExpiryNote);
        }
    }
    const handleHoverOut = () => {
        setIsHovered(false)
        setAnchorEl(null)
    }

    if (tableSliceProperties.data.isFilteredDataError) {
        return <ErrorWithRefetchButton
            text={tableSliceProperties.data.filteredDataErrorMessage}
            onRefetch={() => tableSliceProperties.actions.applyFilters()}
            height={"300px"}
        />
    }
    if (tableSliceProperties.data.isFilteredDataLoading) {
        return <Loading fullHeight={true} />
    }

    return (
        <>
            <StyledPopper open={isHovered} anchorEl={anchorEl} handleClose={handleHoverOut}>
                <Grid container md={12} p={2} width={300}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: '22px !important', color: 'error.main' }} />
                    <Typography variant='h6' color='error.main' fontWeight={700} pl={1}>{t('popups.titles.expired')}</Typography>
                    <Grid container md={12} py={1}> <Typography variant='subtitle2' fontWeight={400}>   {expiryNote}  </Typography> </Grid>
                </Grid>
            </StyledPopper>

            {tableSliceProperties.data.tableData?.rows.length != 0 ?
                tableType == TableMapperType.catalog ?
                    <>
                        {
                            tableSliceProperties.data.tableData?.rows?.map((row: any, index: number) => (
                                <React.Fragment key={row.id}>
                                    {/* {index == 0 &&
                                        <Grid container alignItems={'center'} justifyContent={'space-between'} md={12} sx={{ my: 2 }}>
                                            <Grid container md={12} pl={1}>
                                                {row?.columns?.map((col: any, index: number) =>
                                                    <React.Fragment key={index}>
                                                        {col.type === ColumnType.LINK ? <Grid item container md={1.5} alignItems={'center'} ml={1}> <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ textTransform: 'uppercase' }}> {col.title}</Typography> </Grid> : col.type === ColumnType.LINK_DOUBLE ? <Grid item container md={2} ml={1} alignItems={'center'}>
                                                            <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ textTransform: 'uppercase' }}> {col.title} </Typography> </Grid> : col.type === ColumnType.STRING ? <Grid item md={1} alignItems={'center'}> <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ textTransform: 'uppercase' }}>{col.title}</Typography></Grid> : col.type === ColumnType.DOUBLE ? <Grid item md={1.5}>  <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ textTransform: 'uppercase' }}> {col.title}  </Typography> </Grid> : col.type === ColumnType.LONG ? <Grid item md={2}>  <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ textTransform: 'uppercase' }}>  {col.title}</Typography> </Grid>
                                                                : col.type === ColumnType.READ_TICK ? <Grid item md={1} alignItems={'center'}> <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ textTransform: 'uppercase' }}> {col.title}</Typography>  </Grid>
                                                                    : col.type === ColumnType.DATE ? <Grid item md={1} ml={1}>   </Grid>
                                                                        : <Grid item md={1} alignItems={'center'}> <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ textTransform: 'uppercase' }}> {col.title}</Typography>  </Grid>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </Grid>
                                        </Grid>
                                    } */}
                                    <Grid container md={12} py={1}>
                                        <Accordion expanded={expanded === row.id} onChange={row.children && handleChange(row.id)} sx={{ width: "100%", bgcolor: 'white', boxShadow: 'none' }}>
                                            <AccordionSummary sx={{ '&:focus-within': { backgroundColor: 'transparent' } }} expandIcon={row.children ? <ExpandMoreIcon /> : <ExpandMoreIcon sx={{ color: 'transparent' }} />} aria-controls="panel1bh-content" id="panel1bh-header">
                                                <Grid container alignItems={'center'} justifyContent={'space-between'} md={11}>
                                                    {row?.columns?.map((col: any) =>
                                                        <React.Fragment key={col.name}>
                                                            {/* {col._hasNewChanges ? <Grid item md={0.2}><CircleIcon sx={{ color: 'success.dark', fontSize: '18px', pt: 1 }} /></Grid> : <Grid item md={0.2}></Grid>} */}

                                                            {col.type == ColumnType.LINK && row.form_type &&
                                                                <Grid item container md={1.5} alignItems={'center'}>
                                                                    {col._hasNewChanges ? <CircleIcon sx={{ color: 'success.dark', fontSize: '12px', mr: 1 }} /> : <Grid item md={0.2}></Grid>}
                                                                    <Link underline='hover' href={getTheURL(row.form_type, row.id)} onClick={(event) => event.stopPropagation()}> <Typography variant='subtitle2' fontWeight={400}>  {col.description} </Typography></Link>
                                                                </Grid>
                                                            }

                                                            {col.type == ColumnType.LINK_DOUBLE && row.form_type && row.ownerId &&
                                                                <Grid item container md={2} alignItems={'center'}>
                                                                    <Link underline='hover' href={{ pathname: isSeller() ? sm.portals.seller.usersAccount.url : sm.portals.buyer.usersAccount.url, query: { userId: row.ownerId } }} onClick={(event) => event.stopPropagation()}>
                                                                        <Typography variant='subtitle2' fontWeight={400}>  {col.description} </Typography>
                                                                    </Link>
                                                                </Grid>
                                                            }

                                                            {col.type == ColumnType.DELETE && canUpdateCatalog && <Grid item container md={1} justifyContent='end' onClick={(event) => event.stopPropagation()}>
                                                                <Box onClick={() => !row.referenceObject.currently_in_use && handleDelete(row.id)} width='15px' sx={{ display: 'flex', height: '15px', justifyContent: 'center', alignItems: 'center', backgroundColor: 'warning.contrastText' }}>
                                                                    <DeleteIconSmall style={{ opacity: row.referenceObject.currently_in_use ? 0.2 : 1, cursor: row.referenceObject.currently_in_use ? 'default' : 'cursor' }} />
                                                                </Box>
                                                            </Grid>}
                                                            {col.type == ColumnType.BUTTON && canUpdateCatalog && <Grid container item md={2} gap={1} alignItems='center' justifyContent={'flex-end'} onClick={(event) => event.stopPropagation()} >
                                                                <Button onClick={() => handleButtonAction(row.id)}>{t('buttons.' + col.title)}</Button>
                                                            </Grid>}
                                                            {col.type == ColumnType.BUTTON && canUpdateCatalog && <Grid container item md={2} gap={1} alignItems='center' justifyContent={'flex-end'} onClick={(event) => event.stopPropagation()} >
                                                                <Button onClick={() => handleButtonAction(row.id)}>{t('buttons.' + col.title)}</Button>
                                                            </Grid>}
                                                            {col.type == ColumnType.SELECT && canUpdateCatalog && <Grid container item md={2} gap={1} alignItems='center' justifyContent={'flex-end'} onClick={(event) => event.stopPropagation()} >
                                                                <Grid item md={5}>
                                                                    <StyledSelect
                                                                        items={selectItems ?? []}
                                                                        handleChange={(value: string) => handleChangeSelect(row.id, value)}
                                                                        value={col.description}
                                                                        placeholder={t(getInputsTranslationKey('placeholders.' + col.title))}
                                                                    />
                                                                </Grid>
                                                            </Grid>}
                                                            {col.type == ColumnType.INPUT && canUpdateCatalog && <Grid container item md={2} gap={1} alignItems='center' justifyContent={'flex-end'} onClick={(event) => event.stopPropagation()} >
                                                                <Grid item md={5}>
                                                                    <EditableTextField
                                                                        handleChange={(value: string) => handleChangeInput(row.id, value)}
                                                                        value={col.description}
                                                                        placeholder={t(getInputsTranslationKey('placeholders.' + col.title))}
                                                                    />
                                                                </Grid>
                                                                {col.title && <Grid item md={3}>
                                                                    <Typography variant='subtitle2'>{t(getInputsTranslationKey('labels.' + col.title))}</Typography>
                                                                </Grid>}
                                                            </Grid>}
                                                            {col.type == ColumnType.SWITCH && canUpdateCatalog && <Grid item md={1} container justifyContent='end' onClick={(event) => event.stopPropagation()}>
                                                                <Switch
                                                                    color="success"
                                                                    onChange={() => handleSwitch(row.id, col.description)}
                                                                    checked={col.description} />
                                                            </Grid>}
                                                            {col.type == ColumnType.IMAGE && <Grid item md={1} container sx={{ justifyContent: 'center', alignItems: 'center', px: 1 }}>
                                                                <Box height='50px' paddingX={3}>
                                                                    <img src={col.description} height={'50px'} width={'100%'} style={{ objectFit: 'contain' }} />
                                                                </Box>
                                                            </Grid>}

                                                            {col.type == ColumnType.STRING && <Grid item md={1} paddingX={1}>
                                                                <Typography variant='subtitle2' fontWeight={400}>  {col.description}  </Typography>
                                                            </Grid>}

                                                            {col.type == ColumnType.DOUBLE && <Grid item md={1.5} paddingX={1}>
                                                                <Typography variant='subtitle2' fontWeight={400}>  {col.description}  </Typography>
                                                            </Grid>}

                                                            {col.type == ColumnType.LONG && <Grid item md={2} paddingX={1}>
                                                                <Typography variant='subtitle2' fontWeight={400}>  {col.description}  </Typography>
                                                            </Grid>}

                                                            {col.type == ColumnType.DATE && <Grid item md={1}><Typography variant='subtitle2' fontWeight={400}>{col.description}</Typography></Grid>}

                                                            {col.type == ColumnType.STATUS && <Grid item md={1}><StatusChip status={col.description} /></Grid>}

                                                            {col.type == ColumnType.READ_TICK && <Grid item md={0.5}>{col.description == ReadStatus.PARTIALLY_READ ? <><DoneIcon sx={{ color: 'success.dark', fontSize: '18px' }} /><DoneIcon sx={{ color: 'info.light', fontSize: '18px', ml: -1.4 }} /></> : <DoneAllIcon sx={{ color: col.description == ReadStatus.PENDING ? 'info.light' : col.description == ReadStatus.READ ? 'success.dark' : 'transparent', fontSize: '18px' }} />}</Grid>}

                                                            {col.type == ColumnType.Expiry_mark && <Grid item md={0.8} paddingLeft={1}>{col.description && <Grid container md={12} alignItems={'center'} justifyContent={'end'}><ErrorOutlineOutlinedIcon onMouseEnter={(event) => handleHoverIn(event, row.form_type)} onMouseLeave={handleHoverOut} sx={{ cursor: 'pointer', color: 'error.main', fontSize: '18px' }} /></Grid>}</Grid>}

                                                        </React.Fragment>
                                                    )}
                                                </Grid>
                                            </AccordionSummary>


                                            {row?.children ?
                                                <AccordionDetails sx={{ bgcolor: 'primary.light' }} >

                                                    {row?.children?.map((child: any) =>
                                                        <React.Fragment key={child.id}>
                                                            <Grid container alignItems={'center'} justifyContent={'space-between'} md={11.3} py={1}>
                                                                {
                                                                    child.columns?.map((col: any) =>
                                                                        <React.Fragment key={col.name}>
                                                                            {/* {col._hasNewChanges ? <Grid item md={0.2}><CircleIcon sx={{ color: 'success.dark', fontSize: '18px', pt: 1 }} /></Grid> : <Grid item md={0.2}></Grid>}
    
                                                                  {col.type == 'link' && child.form_type && <Grid item md={1}><Link underline='hover' href={getTheURL(child.form_type, row.id, child.id)}>
                                                                      <Typography variant='subtitle2' fontWeight={400} >  {col.description}  </Typography> </Link>
                                                                  </Grid>
                                                                  } */}
                                                                            {col.type == ColumnType.LINK && child.form_type &&
                                                                                <Grid item container md={1.5} alignItems={'center'}>
                                                                                    {col._hasNewChanges ? <CircleIcon sx={{ color: 'success.dark', fontSize: '12px', mr: 1 }} /> : <Grid item md={0.2}></Grid>}
                                                                                    <Link underline='hover' href={getTheURL(child.form_type, row.id, child.id)}>    <Typography variant='subtitle2' fontWeight={400} >  {col.description}  </Typography> </Link>
                                                                                </Grid>
                                                                            }

                                                                            {col.type == ColumnType.LINK_DOUBLE && row.form_type && row.ownerId &&
                                                                                <Grid item container md={2} alignItems={'center'}>
                                                                                    <Link underline='hover' href={{ pathname: isSeller() ? sm.portals.seller.usersAccount.url : sm.portals.buyer.usersAccount.url, query: { userId: row.ownerId } }} onClick={(event) => event.stopPropagation()}>
                                                                                        <Typography variant='subtitle2' fontWeight={400}>  {col.description} </Typography>
                                                                                    </Link>
                                                                                </Grid>
                                                                            }

                                                                            {col.type == ColumnType.STRING && <Grid item md={1} paddingX={1}>
                                                                                <Typography variant='subtitle2' fontWeight={400}>  {col.description} {col.name == "number_of_sellers" ? t('texts.seller') : null}</Typography>
                                                                            </Grid>
                                                                            }


                                                                            {col.type == ColumnType.DOUBLE && <Grid item md={1.5} paddingX={1}>
                                                                                <Typography variant='subtitle2' fontWeight={400}>  {col.description}  </Typography>
                                                                            </Grid>}

                                                                            {col.type == ColumnType.LONG && <Grid item md={2} paddingX={1}>
                                                                                <Typography variant='subtitle2' fontWeight={400}>  {col.description}  </Typography>
                                                                            </Grid>}

                                                                            {col.type == ColumnType.STATUS && <Grid item md={1.5} textAlign={'center'}><StatusChild status={col.description} /></Grid>}
                                                                            {col.type == ColumnType.READ_TICK && <Grid item md={0.5}>{col.description == ReadStatus.PARTIALLY_READ ? <><DoneIcon sx={{ color: 'success.dark', fontSize: '18px' }} /><DoneIcon sx={{ color: 'info.light', fontSize: '18px', ml: -1.4 }} /></> : <DoneAllIcon sx={{ color: col.description == ReadStatus.PENDING ? 'info.light' : col.description == ReadStatus.READ ? 'success.dark' : 'transparent', fontSize: '18px' }} />}</Grid>}
                                                                            {col.type == ColumnType.DATE && <Grid item md={1}><Typography variant="subtitle2" fontWeight={400}>{col.description} </Typography></Grid>}
                                                                            {col.type == ColumnType.Expiry_mark && <Grid item md={1}>{col.description && <Grid container md={12} alignItems={'center'} justifyContent={'start'} pl={2}><ErrorOutlineOutlinedIcon onMouseEnter={(event) => handleHoverIn(event, child.form_type)} onMouseLeave={handleHoverOut} sx={{ cursor: 'pointer', color: 'error.main', fontSize: '18px' }} /></Grid>}</Grid>}

                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </Grid>
                                                        </React.Fragment>
                                                    )}
                                                </AccordionDetails> : <></>
                                            }
                                        </Accordion>
                                    </Grid>
                                </React.Fragment>
                            ))
                        }
                    </>
                    :
                    <Grid container item md={12} xs={12}>
                        <Box width={'100%'} minHeight={200}>
                            <ProcurementTableData data={tableSliceProperties.data} onRoute={(type: string, id: string, queryId?: string) => getTheURL(type, id, queryId)} />
                        </Box>
                    </Grid>
                : <Grid container md={12} justifyContent={'center'} alignItems={'center'} pt={15}>No result</Grid>
            }

            <Grid container md={12} justifyContent={'center'} alignItems={'center'}>
                {tableSliceProperties.data.tablePaginationResult?.last_page > 1 && (
                    <Box sx={{ my: 5, display: 'flex', justifyContent: 'center' }}>
                        <StyledPagination
                            count={tableSliceProperties.data.tablePaginationResult?.last_page}
                            page={tableSliceProperties.data.tablePaginationResult?.current_page}
                            onChange={(event, value) => {
                                tableSliceProperties.actions.changePageNumber(value);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                        />
                    </Box>
                )}
            </Grid>

        </>
    )
}

export * from './products-data-table';

