import { ProductManagerEnv, ProductManagerPopupStatus } from "@/config";
import { Product, ProductCategoryKind } from "@/models";
import { useProcurementStore } from "@/zustand";
import { Description } from "@mui/icons-material";
import { Button } from "@mui/material";
import { t } from "i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { sm } from "src/site-map";
import { ConfirmPopup, StyledLoadingButton, TwoButtonsWithHoverToggle } from "src/ui/components/shared";
import { ProductQuantity } from ".";
import { NotifyError } from "@/services";

type props = {
    product: Product,
    isPageAlternative?: boolean;
};

export const RequisitionProductActions: React.FC<props> = (props) => {
    const { push, query } = useRouter();
    const { data } = useProcurementStore(state => state.productFormManagerProperties)

    const viewAlternatives = () => {
        push({ pathname: sm.catalogue.products.alternatives(props.product.id).url })
    }
    return (
        <>
            {data.environment?.type !== ProductManagerEnv.purchase_order && <>
                <ProductQuantity product={props.product} isPageAlternative={props.isPageAlternative} />
                <MainProductRequisitionButton {...props.product} />
                {props.product.inCart && !props.isPageAlternative && data.environment?.type === ProductManagerEnv.requisition && <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={viewAlternatives}
                    sx={{lineHeight: 1}}
                > {t("buttons.add_alternatives")}
                </Button>
                }
            </>}
        </>
    )
}


export const MainProductRequisitionButton = (product: Product) => {
    const { data, actions } = useProcurementStore(state => state.productFormManagerProperties)
    const [showDelPopup, setshowDelPopup] = useState(false);
    const handleOpenDelPopup = () => setshowDelPopup(true);
    const handleCloseDelPopup = () => setshowDelPopup(false);
    const handleRemoveProduct = () => {
        actions.removeProduct(product)
        handleCloseDelPopup()
    }
    const handleAddProduct = () => {
        actions.setProductIdRequestedToBeAdded(product.id);
        if(product.category_kind === ProductCategoryKind.SERVICE && !product.additional_requirements) {
            NotifyError("Missing Field: Buyer Requirements");
            return;
        }

        if (data.environment?.type === ProductManagerEnv.requisition) {
            actions.addProduct(product)
        } else {
            actions.setCandidateProductToAdd(product)
            actions.setProductManagerPopupStatus(ProductManagerPopupStatus.SELECT_REQUISITION)
        }
    }

    return (
        <>
            {product.inCart && data.environment?.type === ProductManagerEnv.requisition ?
                <>
                    <TwoButtonsWithHoverToggle handleClickHiddenButton={handleOpenDelPopup} hiddenButtonTitleKey="remove" mainButtonTitleKey="added_to_requisition"  data-testid={`remove-from-requisition-${product.id}`} />
                    <ConfirmPopup
                        titleKey={t("popups.titles.remove_product")}
                        subTitleKey={t('popups.subTitles.remove_product', { formName: data.formDetails?.name })}
                        buttonTitleKey={t('buttons.remove')}
                        handleClose={handleCloseDelPopup}
                        handleConfirm={handleRemoveProduct}
                        open={showDelPopup}
                    />
                </>
                : <StyledLoadingButton fullWidth variant="contained" icon={<Description />} onClick={handleAddProduct} translateKey="add_to_requisition" style={{lineHeight:1}} data-testid={`add-to-requisition-${product.id}`} />
            }
        </>
    )
}
