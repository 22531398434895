import { Product, ProductCategoryKindLabel } from '@/models';
import { useUserData } from '@/zustand';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BadgeIcon from '@mui/icons-material/Badge';
import { Box, Grid, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sm } from 'src/site-map';
import { HistoryType, ProductHistory } from 'src/ui/components/dumb';
import SellerProductHistory from 'src/ui/components/dumb/shared/product-history/SellerProductHistory';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import { iconStyles, productHistoryTextStyles } from '../product-details-header/styles';
import { Label, Value } from '../products-details-page/tabs/components';

type IProps = {
  product: Product;
  disableNavigation?: boolean;
};

const ProductQuickView = ({ product, disableNavigation = false }: IProps) => {
  const { t } = useTranslation();
  const isSeller = useUserData().isSeller();
  const isBuyer = useUserData().isBuyer();
  const purchaseHistoryItems = product?.purchaseHistoryItems;
  const quoteHistoryItems = product?.quote_history;

  const specItems = React.useMemo(
    () =>
      product.productAttributes.map(attr => ({
        label: attr.name,
        value: attr.value,
      })),
    []
  );

  return (
    <div style={{ width: '100%', paddingInline: '1rem' }}>
      <Grid container md={12} gap={2} display={'flex'} flexWrap={'nowrap'} width={'100%'}>
        <Grid
          item
          md={4}
          bgcolor={'#fff'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          position={'relative'}
          borderRadius={'8px'}
        >
          <Typography
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: 2,
              backgroundColor: '#fff',
              border: '1px solid #3A3E50',
              padding: '.15rem 1.25rem',
              borderRadius: '16px',
            }}
            color='#3a3e50'
            fontSize={13}
            fontWeight={500}
          >
            {ProductCategoryKindLabel[product.category_kind]}
          </Typography>
          <img
            width='90%'
            height='90%'
            style={{ objectFit: 'contain', maxHeight: '150px', display: 'block' }}
            src={product.image_url}
            alt=''
          />
        </Grid>
        <Grid item md={8}>
          <Box gap={1} width='100%'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '100%', display: 'flex', gap: '1rem', alignItems: 'center' }}>
                {product.brand && (
                  <img
                    src={product.brand.image_url}
                    style={{
                      objectFit: 'contain',
                      maxWidth: '50px',
                      maxHeight: '50px',
                      display: 'block',
                      marginBottom: '1rem',
                    }}
                  />
                )}
                {product.part_number && (
                  <Typography fontSize={12} sx={{ fontWeight: 400 }} color='#8B8D98'>
                    Part Number: {product.part_number}
                  </Typography>
                )}
              </div>
              <div style={{ flexShrink: 0, display: 'flex', justifyContent: 'flex-end', paddingInlineEnd: '2rem' }}>
                {product?.is_private_product && <PrivacyButton zIndex={1300} />}
              </div>
            </div>
            {disableNavigation ? (
              <Typography variant='overline' sx={{ fontWeight: 600 }}>
                {product.name}
              </Typography>
            ) : (
              <Link href={sm.catalogue.products.productId(`${product.id}`).url}>
                <Typography
                  variant='overline'
                  sx={{ fontWeight: 600, cursor: 'pointer' }}
                  className='underline-on-hover'
                >
                  {product.name}
                </Typography>
              </Link>
            )}
          </Box>

          <Grid container md={12} alignItems={'center'} mt={1}>
            <Grid item md={6}>
              <div style={{ display: 'flex' }}>
                <div style={iconStyles}>
                  <BadgeIcon color='primary' />
                </div>
                <div>
                  <Typography variant='subtitle1' lineHeight={1.2} fontWeight={400} color='#C5C6CB'>
                    Sellers
                  </Typography>
                  <Typography variant='body2' fontWeight={700} color='#3A3E50'>
                    {product.sellers?.length ?? 0} Sellers
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              {isBuyer && purchaseHistoryItems?.length != 0 && (
                <ProductHistory
                  items={purchaseHistoryItems}
                  title={t('texts.purchase_history')}
                  type={HistoryType.purchase}
                  iconColor='#13628C'
                  iconText={<span style={productHistoryTextStyles}>View Purchase History</span>}
                />
              )}
              {isSeller && (purchaseHistoryItems?.length > 0 || quoteHistoryItems?.length > 0) && (
                <SellerProductHistory
                  po_items={purchaseHistoryItems}
                  quote_items={quoteHistoryItems}
                  iconColor='#13628C'
                  iconText={<span style={productHistoryTextStyles}>View History</span>}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {product.description && (
        <Typography fontWeight={400} color='#3A3E50' fontSize={14} my={1}>
          {product.description}
        </Typography>
      )}

      {specItems.length > 0 && (
        <Box my={2}>
          <Grid container md={12} columnSpacing={2}>
            {specItems.map((item, index) => (
              <Grid
                key={item.label}
                md={4}
                item
                sx={{ py: 0.5, borderInlineEnd: (index + 1) % 3 !== 0 ? '1px solid #C5C6CB' : 'none' }}
              >
                <Label text={item.label} />
                <Value text={item.value} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {product.documents && product.documents.length > 0 && (
        <Box sx={{ borderTop: '1px solid #C5C6CB' }} mt={1} pt={1}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {product.documents.map(doc => (
              <a
                key={doc.id}
                href={doc.url}
                target='_blank'
                rel='noreferrer noopener'
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  color='#13628C'
                  fontSize={14}
                  fontWeight={400}
                  className='underline-on-hover'
                  style={{ cursor: 'pointer' }}
                  mr={2.5}
                  mb={1}
                >
                  <AttachFileIcon sx={{ fontSize: 20, transform: 'translateY(4px)' }} /> {doc.name}
                </Typography>
              </a>
            ))}
          </div>
        </Box>
      )}
    </div>
  );
};

export default ProductQuickView;
