import { procurementStatus } from "@/config";
import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import React from "react";
import { StyledPopper } from "src/ui/components/shared";

interface StatusChipProps {
    status: procurementStatus | undefined,
    title?: string,
    listOfApprovers?: { user_name: string, status: string }[]
    withOutBorder?: boolean
    minWidth?: string
}
export const StatusChip: React.VFC<StatusChipProps> = ({ status, title, listOfApprovers, withOutBorder, minWidth }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const showListOfApprovers = Boolean(anchorEl);
    const mainStatus = [procurementStatus.UNLINKED, procurementStatus.MIXED, procurementStatus.PARTIALLY_QUOTES_RECEIVED, procurementStatus.QUOTES_RECEIVED, procurementStatus.EDITING, procurementStatus.QUOTE_RECEIVED, procurementStatus.PO_RECEIVED, procurementStatus.RFQ_RECEIVED, procurementStatus.DRAFT, procurementStatus.PARTIALLY_APPROVED, procurementStatus.PARTIALLY_APPROVED_BY_ME, procurementStatus.PENDING, procurementStatus.QUOTE_GENERATED, procurementStatus.PARTIALLY_SENT_TO_SELLERS, procurementStatus.READY]
    const dangerStatus = [procurementStatus.UNPUBLISHED, procurementStatus.REVOKED, procurementStatus.QUOTE_EXPIRED, procurementStatus.RFQ_EXPIRED, procurementStatus.DECLINED, procurementStatus.REJECTED, procurementStatus.REJECTED_BY_ME, procurementStatus.CANCELED, procurementStatus.RECALLED, procurementStatus.PARTIALLY_DECLINED]
    const warningStatus = [procurementStatus.PENDING_APPROVAL, procurementStatus.PENDING_MY_APPROVAL, procurementStatus.SENT_FOR_APPROVAL, procurementStatus.REQUESTED, "PENDING_QUOTES"]
    const successStatus = [procurementStatus.MULTI_POS_GENERATED, procurementStatus.PO_GENERATED, procurementStatus.PUBLISHED, procurementStatus.APPROVED, procurementStatus.CONFIRMED, procurementStatus.APPROVED_BY_ME, procurementStatus.SENT_TO_SELLERS, procurementStatus.SENT_TO_SELLER, procurementStatus.SENT_TO_BUYER, procurementStatus.CONVERTED_TO_RFQ, procurementStatus.PARTIALLY_APPROVED, procurementStatus.PARTIALLY_SENT_TO_SELLERS, procurementStatus.PARTIALLY_CONFIRMED]
    const statuses = {}
    mainStatus.forEach(key => statuses[key] = 'main');
    dangerStatus.forEach(key => statuses[key] = 'danger');
    warningStatus.forEach(key => statuses[key] = 'warning');
    successStatus.forEach(key => statuses[key] = 'success');

    let canClickOnChip = listOfApprovers && listOfApprovers.length
    return (
        <>
            <Chip label={t('chip.' + status)}
                variant={statuses[status] == 'main' ? "outlined" : 'filled'}
                sx={{
                    height: "30px",
                    minWidth: minWidth ? minWidth : "144px",
                    border: withOutBorder ? 'none' : `1px solid ${statuses[status] == 'danger' ? 'rgba(255, 77, 77, 1)' : statuses[status] == 'warning' ? 'rgba(251, 127, 13, 1)' : statuses[status] == 'success' ? 'rgba(10, 184, 123, 1)' : 'rgba(58, 62, 80, 1)'}`,
                    color: statuses[status] == 'danger' ? 'rgba(255, 77, 77, 1)' : statuses[status] == 'warning' ? 'rgba(251, 127, 13, 1)' : statuses[status] == 'success' ? 'rgba(10, 184, 123, 1)' : 'rgba(58, 62, 80, 1)',
                    cursor: 'inherit',
                    bgcolor: withOutBorder ? 'transparent' : 'white'
                }}
            // onClick={(event: React.MouseEvent<HTMLElement>) => canClickOnChip && setAnchorEl(event.currentTarget)}
            />
            <StyledPopper open={showListOfApprovers} anchorEl={anchorEl} handleClose={() => setAnchorEl(null)}>
                <div style={{ padding: 15 }}>
                    <Typography variant="body2" fontWeight={600} color={'primary.main'}>{title}</Typography>
                    <Box width={"100%"} mt={1} mb={2}> <Divider sx={{ borderColor: 'info.contrastText', opacity: 0.7 }} /></Box>
                    {listOfApprovers?.map(approver => (
                        <Grid container justifyContent={'space-between'} gap={5}>
                            <Typography color="text.disabled" variant="subtitle2">{approver.user_name}</Typography>
                            <Typography variant="subtitle2"
                                color={statuses[approver.status] == 'danger' ? 'error' : statuses[approver.status] == 'warning' ? 'secondary' : statuses[approver.status] == 'success' ? 'success.dark' : 'primary'}>
                                {t('chip.' + approver.status)}
                            </Typography>
                        </Grid>
                    ))}
                </div>
            </StyledPopper>
        </>
    )
}