import { request } from '@/helpers';
import { ProcurementFormModel } from '@/models';
import { useInfiniteQuery } from '@tanstack/react-query';
import { PaginatedResponse } from 'src/logic/models/queries';

export const useGetEditablePurchaseOrders = () => {
  return useInfiniteQuery<PaginatedResponse<ProcurementFormModel>>({
    queryKey: ['EDITABLE_PURCHASE_ORDERS'],
    queryFn: async ({ pageParam }) => {
      const response = await request({
        url: '/buyer/purchase_order/index-for-product',
        method: 'GET',
        params: {
          page: pageParam,
        },
      });
      return response.data;
    },
    retry: 0,
    refetchOnMount: true,
    getNextPageParam: lastPage => {
      const { current_page, last_page } = lastPage.meta;
      if (current_page === last_page) {
        return undefined;
      }
      return current_page + 1;
    },
  });
};
