import { request } from '@/helpers';
import { SortConfig } from 'src/logic/models/@shared/sort';
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters';
import { CatalogueSeller } from 'src/logic/models/catalogue/catalogue-content';
import { PaginatedResponse } from 'src/logic/models/queries';
import { CatalougeFilterItem } from '../products';

export const getSellersCatalogueIndex = () => {
  return request({ url: `/product/sellers`, method: 'GET', params: { only_my_seller: false } });
};

export const getFilteredSellers = (id?: string, showMySellers?: boolean) => {
  const url = `/product/sellers`;
  return request({
    url,
    method: 'GET',
    params: {
      ...(showMySellers != undefined && { only_my_seller: showMySellers }),
      ...(id && { sort_by: id }),
    },
  });
};

export const getSellerDetails = (id: number, category_kind?: number | null) => {
  return request({
    url: `/product/sellers/${id}`,
    method: 'GET',
    params: category_kind ? { category_kind } : undefined,
  });
};

// ================================ New Catalogue ================================

export type CatalogueSellersTabResponse = {
  data: PaginatedResponse<CatalogueSeller>;
  sort_data: SortConfig;
};

export const getSellersItems = ({
  page,
  search,
  sortBy,
  environmentFilters = {},
  filters,
}: {
  page: number;
  search?: string;
  sortBy?: string;
  environmentFilters?: EnvironmentFilters;
  filters?: CatalougeFilterItem[];
}): Promise<{
  data: CatalogueSellersTabResponse;
}> => {
  return request({
    url: `/product/catalog/sellers_tab`,
    method: 'POST',
    data: {
      query: search,
      page,
      sort_by: sortBy,
      filters,
      ...environmentFilters,
    },
  });
};
