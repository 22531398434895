import { request } from '@/helpers';

export const getLocations = () => {
  return request({
    url: `/buyer/catalog/product/seller/add`,
    method: 'GET',
  });
};

export const getLocationsForCreateBuyer = () => {
  return request({
    url: `/seller/company-section/create-buyer`,
    method: 'GET',
  });
};
export const storeNewBuyer = (data: any, options = {}) => {
  return request({
    url: `/seller/company-section/store-buyer`,
    method: 'POST',
    data: data,
    ...options,
  });
};

export const getLocationsForCreateSeller = () => {
  return request({
    url: `/buyer/company-section/create-seller`,
    method: 'GET',
  });
};

export const storeNewSeller = (data: any, options = {}) => {
  return request({
    url: `/buyer/company-section/store-seller`,
    method: 'POST',
    data: data,
    ...options,
  });
};

export const getALLExistingSellers = (productId: number) => {
  return request({
    url: `/requisition/${productId}/un-link-sellers`,
    method: 'GET',
  });
};

export const getSellerCompanies = (productId: number, name: string) => {
  return request({
    url: `/buyer/catalog/product/seller/suggest-sellers`,
    method: 'POST',
    data: {
      product_id: productId ?? null,
      name: name,
    },
  });
};

export const linkSellersInProducts = (data: any, options = {}): Promise<any> => {
  return request({
    url: `/buyer/catalog/product/link-to-seller`,
    method: 'POST',
    data,
    ...options,
  });
};

export const getUnlinkSeller = (sellerId: string, productId: number): Promise<any> => {
  return request({
    url: `/buyer/catalog/product/link-to-seller`,
    method: 'DELETE',
    data: {
      seller_id: sellerId,
      product_id: productId,
    },
  });
};

export const linkSellersInRequisition = (data: any, options = {}): Promise<any> => {
  return request({
    url: `/requisition/link-sellers`,
    method: 'POST',
    data,
    ...options,
  });
};

export const UpdateBuyer = (data: any, options = {}, companyId: number | undefined) => {
  return request({
    url: `/seller/company-section/update-buyer/${companyId}`,
    method: 'POST',
    data: data,
    ...options,
  });
};

export const UpdateSeller = (data: any, options = {}, companyId: number | undefined) => {
  return request({
    url: `/buyer/company-section/update-seller/${companyId}`,
    method: 'POST',
    data: data,
    ...options,
  });
};

export const DeleteCompanyBuyer = (companyId: number | undefined, options = {}) => {
  return request({
    url: `/seller/company-section/delete-buyer/${companyId}`,
    method: 'DELETE',
    ...options,
  });
};

export const DeleteCompanySeller = (companyId: number | undefined, options = {}) => {
  return request({
    url: `/buyer/company-section/delete-seller/${companyId}`,
    method: 'DELETE',
    ...options,
  });
};

export const linkProduct = (productId: number) => {
  return request({
    url: `/seller/catalog/link-product/${productId}`,
    method: 'POST',
    disableErrorMessage: true
  });
};


export const getLinkAllResults = (data = {}) => {
  return request({
    url: `product/catalog/linkallproducts`,
    method: 'POST',
    data: data
  });
};

export const getUnLinkAllResults = () => {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      const isSuccess = Math.random() > 0.2;
      if (isSuccess) {
        resolve();
      } else {
        reject(new Error('Failed to unlink all results'));
      }
    }, 2000);
  })
};

export const unlinkCatalogueItemService = (catalogueItemId: number) => {
  return request({
    url: `/seller/catalog/delete/${catalogueItemId}`,
    method: 'DELETE',
    disableErrorMessage: true
  });
};

export const unlinkCatalogueItemsService = (data = {}) => {
  return request({
    url: `/seller/catalog/unlink-products`,
    method: 'POST',
    data: data,
    disableErrorMessage: true
  });
};

export const updateCatalogueItemService = (catalogueItemId: number, data = {}) => {
  return request({
    url: `/seller/catalog/update/${catalogueItemId}`,
    method: 'PUT',
    data: data,
    disableErrorMessage: true
  });
};

export const updateCatalogueItemsService = (data = {}) => {
  return request({
    url: `/seller/catalog/bulk-update`,
    method: 'PUT',
    data: data,
    disableErrorMessage: true
  });
};

export const linkSelectedProductsService = (data = {}) => {
  return request({
    url: `/seller/catalog/link-products`,
    method: 'POST',
    data: data,
    disableErrorMessage: true
  });
};