import { AuthenticateStatus, ProcurementButtons, RequisitionPopupStatus, RequisitionStatus } from "@/config";
import { GeneralObject, ProcurementModelDetails, Product, Requisition, RequisitionProduct, TabModel } from "@/models";
import {
    deleteRequisition, getRquisitionDetails, saveRequisition,
    useAddAlternativeProductToRequisition,
    useAddProductToRequisition,
    useChangeProductQuantity,
    useChangeProductRequirements,
    useChangeProject,
    useCreateRequisition,
    useRegenerateRFQFromRequisition,
    useRemoveAlternativeProductFromRequisition,
    useRemoveProductFromRequisition
} from "@/services";
import produce from "immer";
import { useUserData } from 'src/logic/zustand/user';
import { StateCreator } from "zustand";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";

export interface RequisitionSlice {
    requisitions: Requisition[],
    persisted_requisitions: Requisition[],
    currentRequisition: Requisition | null,
    draftRequisitions: Requisition[]
    canEditRequisition: boolean
    canEditAndRegenerate: boolean,
    isRequisitionPopupOpened: boolean,
    candidateProductToAdd?: Product,
    quantityTimer: number | null,
    numberOfCurrentMainProducts: number
    numberOfDraftRequisitions: number
    requisitionPopupStatus: RequisitionPopupStatus,
    copyOfCurrentRequisition: Requisition | null
    requisitionTabs: TabModel[] | null,
    customLoadingButton: ProcurementButtons | null,
    isRequisitionSelected: () => boolean,
    closeRequisitionPopup: () => void,
    openRequisitionPopup: (requisitionPopupStatus: RequisitionPopupStatus) => void,
    createRequisition: (projectId: string, projectName: string, handleFailure: (error: string) => void, product?: Product, goToDraftRequisition?: (requisitionId: number) => void) => void
    setRequisitionPopupStatus: (requisitionPopupStatus: RequisitionPopupStatus) => void,
    addProductToRequisition: (product: Product) => void,
    addProductByRequisitionId: (product: Product, requisitionId: number) => void
    switchToRequisition: (requisition: Requisition) => void
    setCurrentRequisition: (requisition: Requisition) => void
    setCurrentRequisitionById: (requisitionId: number) => void
    removeProductFromRequisition: (product: Product, removeProductUpdate?: () => void,) => void
    removeProductFromRequisitionWithPersistence: (product: Product, removeProductUpdate?: () => void,) => void
    removeMainProductFromRequisition: (product: Product) => void
    transformProduct: (product: Product, requisition: Requisition) => Product
    buildInitialProductState: (product: Product) => Product
    buildInitialProductAlternativeState: (product: Product, mainProductId: string) => Product
    updateRequisitionProductQuantity: (product: Product, quantity: number) => void
    updateRequisitionProductQuantityWithPersistence: (product: Product, quantity: number) => void
    updateRequisitionProductRequirementsWithPersistence: (product: Product, additional_requirements: string) => void
    changeRequisitionProductQuantity: (product: Product, persist: boolean) => void
    deleteCurrentRequisition: () => void
    deleteRequisitionFromRequisitions: (requisition: Requisition, handleDeleteRequisition: () => void) => void
    setRequisitions: (requisitions: Requisition[]) => void
    setPersistedRequisitions: (requisitions: Requisition[]) => void
    requisitionProductIsChanging: boolean
    setProductLoadingState: (state: boolean) => void
    editAndRegenerateRFQ: (requisitionId: number, handleEditAndRegenerateRFQ: (data: Requisition) => void) => void
    requisitionSliceActions: {
        refreshRequisition: (requisition: Requisition) => void
        saveFormProducts: (requisition: Requisition, products: Product[], handleSavingSucess: (data: ProcurementModelDetails) => void, onError?: () => void) => void
        setProducts: (requisitionId: number, products: Product[]) => void
        getProductsArrayFromRequisition: (requisition: Requisition) => Product[]
        fetchDetails: (formId: string, handlerAfterFetch: (requisition: Requisition) => void) => void
        handleSelectForm: (values: { product: Product, requisitionId: number }, handlerAfterFetch: (requisition: Requisition) => void) => void
        create: (values: { projectId: string, projectName: string }, handlerAfterCreate: (data: ProcurementModelDetails) => void, onError?: () => void) => void
    }
    changeProject: (project: GeneralObject) => void
    saveRequisition: (requisition: Requisition) => void
    cancelAddingItemsToRequisition: () => void,
    currentRequisitionHasChangesToSave: boolean,
    markRequisitionHasNewChanges: () => void,
    setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
}

export const createRequisitionSlice: StateCreator<RequisitionSlice> = (set, get, api) => ({
    currentRequisitionHasChangesToSave: false,
    requisitions: [],
    persisted_requisitions: [],
    numberOfDraftRequisitions: 0,
    draftRequisitions: [],
    currentRequisition: null,
    canEditRequisition: false,
    canEditAndRegenerate: false,
    isRequisitionPopupOpened: false,
    quantityTimer: null,
    requisitionPopupStatus: RequisitionPopupStatus.loading,
    numberOfCurrentMainProducts: 0,
    copyOfCurrentRequisition: null,
    requisitionProductIsChanging: false,
    requisitionTabs: null,
    customLoadingButton: null,

    isRequisitionSelected: () => !!get().currentRequisition,
    setProductLoadingState: (state: boolean) => {
        set(produce(draftState => { draftState.requisitionProductIsChanging = state }))
    },
    switchToRequisition: (requisition: Requisition) => {
        const authenticationStatus = useUserData.getState().authenticationStatus
        // set(produce(draftState => {
        //     draftState.requisitionDetailsSliceState.loadingDraftRequistion = true
        // }))
        set(produce(draftState => {
            draftState.requisitionDetailsSliceState.fetchRequisitionDetailsQueryStatus = QueryHandler.getSuccessStatus();
        }))
        if (authenticationStatus == AuthenticateStatus.AUTHENTICATED)
            getRquisitionDetails(requisition.id).then(data => {
                //get().requisitionsDetailsSliceActions.updateRequisition(data.data)
                get().setCurrentRequisition(data.data)
            })
        else
            get().setCurrentRequisition(get().requisitions.find(e => e.id == requisitionId))
        //get().requisitionsDetailsSliceActions.updateRequisition(get().requisitions.find(e => e.id == requisitionId))
    },
    setCurrentRequisition: (requisition: Requisition, creating?: boolean) => {
        const userId = useUserData.getState().userInfo?.id
        let isOwner = requisition.owner_id == userId
        set(produce(draftState => {
            const updatedRequisitions = draftState.requisitions.map(mappedRequisition => {
                if (mappedRequisition.id === requisition?.id)
                    return requisition
                return mappedRequisition
            })
            if (!creating) draftState.requisitions = updatedRequisitions
            if (!creating) draftState.draftRequisitions = getDraftRequisitions(updatedRequisitions)
            let tabsArray: TabModel[] = []
            requisition?.products?.map((req: RequisitionProduct) => tabsArray.push({ id: req.main_product.id, name: req.main_product.name, count: req.alternative_products.length , isPrivate: req.main_product.is_private_product}))
            draftState.canEditRequisition = isOwner && requisition.status == RequisitionStatus.DRAFT
            draftState.canEditAndRegenerate = requisition?.can_edit_regenerate ?? false
            draftState.currentRequisition = requisition
            draftState.requisitionTabs = tabsArray
        }))
        get().markRequisitionHasNewChanges()
    },
    setCurrentRequisitionById: (requisitionId: number) => {
        get().setCurrentRequisition(get().requisitions?.find(requisition => requisition.id == requisitionId))
    },
    addProductByRequisitionId: (product: Product, requisitionId: number) => {
        const requisition = get().requisitions.find(requisition => requisition.id == requisitionId)
        if (requisition) {
            useAddProductToRequisition(product, requisition).then(data => {
                let updatedRequisition: Requisition | undefined = undefined
                updatedRequisition = data.data
                get().setCurrentRequisition(updatedRequisition)
                get().markRequisitionHasNewChanges()
                set(produce(draftState => {
                    draftState.isRequisitionPopupOpened = false
                }))
            }).catch(e => {
                get().setCurrentRequisition(requisition)
                get().closeRequisitionPopup()
            })
        }
    },
    removeMainProductFromRequisition: (product: Product) => {
        const transformedProduct = produce(product, (draftProdct) => get().transformProduct(get().buildInitialProductState(draftProdct), get().currentRequisition))
        get().removeProductFromRequisition(transformedProduct)
    },
    subscribeCurrentRequisition: () => {
        api.subscribe(
            state => {
                const currentRequisition = state.currentRequisition
                if (JSON.stringify(currentRequisition) !== JSON.stringify(get().copyOfCurrentRequisition))
                    set(produce(draftState => {
                        draftState.numberOfCurrentMainProducts = currentRequisition ? draftState.currentRequisition.products.length : 0
                        draftState.copyOfCurrentRequisition = currentRequisition
                    }))
            },
        )
    },
    removeProductFromRequisition: (product: Product, removeProductUpdate?: () => void) => {
        let updatedRequisition: Requisition | undefined = undefined
        get().setProductLoadingState(true)
        if (product.inCartType == 'main')
            useRemoveProductFromRequisition(product, get().currentRequisition).then(data => {
                if (removeProductUpdate) removeProductUpdate()
                updatedRequisition = data.data
                get().setCurrentRequisition(updatedRequisition)
                if (updatedRequisition) {
                    get().setProductLoadingState(false)
                }
                get().markRequisitionHasNewChanges()
            })
        if (product.inCartType == 'alternative')
            useRemoveAlternativeProductFromRequisition(product, get().currentRequisition).then(data => {
                updatedRequisition = data.data
                get().setCurrentRequisition(updatedRequisition)
                if (updatedRequisition) {
                    set(produce(draftState => {
                        draftState.requisitionProductIsChanging = true
                    }))
                    get().setProductLoadingState(false)
                }
                get().markRequisitionHasNewChanges()
            })
    },
    removeProductFromRequisitionWithPersistence: (product: Product, removeProductUpdate?: () => void) => {
        let updatedRequisition: Requisition | undefined = undefined
        get().setProductLoadingState(true)
        if (product.inCartType == 'main')
            useRemoveProductFromRequisition(product, get().currentRequisition, true).then(data => {
                if (removeProductUpdate) removeProductUpdate()
                updatedRequisition = data.data
                get().setCurrentRequisition(updatedRequisition)
                if (updatedRequisition) {
                    get().setProductLoadingState(false)
                }
                set(produce(draftState => {
                    draftState.persisted_requisitions = draftState.persisted_requisitions.map(item => item?.id == updatedRequisition?.id ? data.data : item)
                }))
                get().markRequisitionHasNewChanges()
            })
        if (product.inCartType == 'alternative')
            useRemoveAlternativeProductFromRequisition(product, get().currentRequisition, true).then(data => {
                updatedRequisition = data.data
                get().setCurrentRequisition(updatedRequisition)
                if (updatedRequisition) {
                    set(produce(draftState => {
                        draftState.requisitionProductIsChanging = true
                    }))
                    get().setProductLoadingState(false)
                }
                set(produce(draftState => {
                    draftState.persisted_requisitions = draftState.persisted_requisitions.map(item => item?.id == updatedRequisition?.id ? data.data : item)
                }))
                get().markRequisitionHasNewChanges()
            })
    },
    updateRequisitionProductQuantity: (product: Product, quantity: number) => {
        const updatedProduct = produce(product, (draftProduct) => {
            draftProduct.quantity = quantity
        })
        let currentRequisition = produce(get().currentRequisition, (draftRequisition) => {
            draftRequisition.products = get().currentRequisition?.products.map(requisitionProduct => {
                return produce(requisitionProduct, (draftProduct) => {
                    if (requisitionProduct.main_product.id == updatedProduct.id) draftProduct.quantity = updatedProduct.quantity
                })
            })
        })
        get().setCurrentRequisition(currentRequisition)
        get().changeRequisitionProductQuantity(updatedProduct, false)
        get().markRequisitionHasNewChanges()
    },
    updateRequisitionProductQuantityWithPersistence: (product: Product, quantity: number) => {
        const updatedProduct = produce(product, (draftProduct) => {
            draftProduct.quantity = quantity
        })
        let currentRequisition = produce(get().currentRequisition, (draftRequisition) => {
            draftRequisition.products = get().currentRequisition?.products.map(requisitionProduct => {
                return produce(requisitionProduct, (draftProduct) => {
                    if (requisitionProduct.main_product.id == updatedProduct.id) draftProduct.quantity = updatedProduct.quantity
                })
            })
        })
        get().setCurrentRequisition(currentRequisition)
        get().changeRequisitionProductQuantity(updatedProduct, true)
        //get().markRequisitionHasNewChanges()
    },
    updateRequisitionProductRequirementsWithPersistence: (product: Product, additional_requirements: string) => {
        const updatedProduct = produce(product, (draftProduct) => {
            draftProduct.additional_requirements = additional_requirements
        })
        let currentRequisition = produce(get().currentRequisition, (draftRequisition) => {
            draftRequisition.products = get().currentRequisition?.products.map(requisitionProduct => {
                return produce(requisitionProduct, (draftProduct) => {
                    if (requisitionProduct.main_product.id == updatedProduct.id) draftProduct.additional_requirements = updatedProduct.additional_requirements
                })
            })
        })
        get().setCurrentRequisition(currentRequisition)
        if(additional_requirements) {
            get().changeRequisitionProductRequirements(updatedProduct, true)
        }
        //get().markRequisitionHasNewChanges()
    },
    changeRequisitionProductRequirements: (product: Product, persist: boolean) => {
        clearTimeout(get().quantityTimer)
        const newTimer = setTimeout(() => {
            useChangeProductRequirements(product, get().currentRequisition, persist).then(data => {
                let currentRequisition = produce(get().currentRequisition, (draftRequisition) => {
                    draftRequisition.products = get().currentRequisition?.products.map(requisitionProduct => {
                        return produce(requisitionProduct, (draftProduct) => {
                            if (requisitionProduct.main_product.id == product.id) draftProduct.additional_requirements = product.additional_requirements
                        })
                    })
                })
                set(produce(draftState => {
                    draftState.requisitions = get().requisitions.map(requisition => {
                        if (requisition.id == currentRequisition?.id) return currentRequisition
                        return requisition
                    })
                }))

                if (persist) {
                    set(produce(draftState => {
                        draftState.persisted_requisitions = draftState.persisted_requisitions.map(item => item?.id == currentRequisition?.id ? currentRequisition : item)
                    }))
                }
                get().markRequisitionHasNewChanges()
            })
        }, 2000)
        set(produce(draftState => { draftState.quantityTimer = newTimer }))

    },
    changeRequisitionProductQuantity: (product: Product, persist: boolean) => {
        clearTimeout(get().quantityTimer)
        const newTimer = setTimeout(() => {
            useChangeProductQuantity(product, get().currentRequisition, persist).then(data => {
                let currentRequisition = produce(get().currentRequisition, (draftRequisition) => {
                    draftRequisition.products = get().currentRequisition?.products.map(requisitionProduct => {
                        return produce(requisitionProduct, (draftProduct) => {
                            if (requisitionProduct.main_product.id == product.id) draftProduct.quantity = product.quantity
                        })
                    })
                })
                set(produce(draftState => {
                    draftState.requisitions = get().requisitions.map(requisition => {
                        if (requisition.id == currentRequisition?.id) return currentRequisition
                        return requisition
                    })
                }))

                if (persist) {
                    set(produce(draftState => {
                        draftState.persisted_requisitions = draftState.persisted_requisitions.map(item => item?.id == currentRequisition?.id ? currentRequisition : item)
                    }))
                }
                get().markRequisitionHasNewChanges()
            })
        }, 500)
        set(produce(draftState => { draftState.quantityTimer = newTimer }))

    },
    addProductToRequisition: (product: Product) => {
        if (get().isRequisitionSelected()) {
            get().setProductLoadingState(true)
            let updatedRequisition: Requisition | undefined = undefined
            if (product.inCartType == 'main')
                useAddProductToRequisition(product, get().currentRequisition).then(data => {
                    updatedRequisition = data.data
                    get().setCurrentRequisition(updatedRequisition)
                    if (updatedRequisition) {
                        get().setProductLoadingState(false)
                    }
                    get().markRequisitionHasNewChanges()
                })
            if (product.inCartType == 'alternative')
                useAddAlternativeProductToRequisition(product, get().currentRequisition).then(data => {
                    updatedRequisition = data.data
                    if (updatedRequisition) {
                        get().setCurrentRequisition(updatedRequisition)
                        get().setProductLoadingState(false)
                    }
                    get().markRequisitionHasNewChanges()
                })
        }
        else {
            if (get().numberOfDraftRequisitions)
                get().openRequisitionPopup(RequisitionPopupStatus.selectRequisition)
            if (!get().numberOfDraftRequisitions)
                get().openRequisitionPopup(RequisitionPopupStatus.createRequisitionWithSelect)
            set(produce(draftState => { draftState.candidateProductToAdd = product }))
        }
    },
    setPersistedRequisitions: (requisitions: Requisition[]) => {
        set(produce(draftState => {
            draftState.persisted_requisitions = produce(requisitions, (draftRequisitions) => draftRequisitions?.map(requisition => produce(requisition, (draftRequisition) => {
                draftRequisition.products = draftRequisition.products.map(requisitionProduct => produce(requisitionProduct, (draftRequisitionProduct) => {
                    draftRequisitionProduct.main_product = get().transformProduct(get().buildInitialProductState(draftRequisitionProduct.main_product), draftRequisition)
                    draftRequisitionProduct.alternative_products = draftRequisitionProduct.alternative_products.map(alternative => get().transformProduct(get().buildInitialProductAlternativeState(alternative, draftRequisitionProduct.main_product.id), draftRequisition))
                    return draftRequisitionProduct
                }))
            }))) ?? []
        }))
        get().markRequisitionHasNewChanges()
    },
    setRequisitions: (requisitions: Requisition[]) => {
        set(produce(draftState => {
            draftState.requisitions = produce(requisitions, (draftRequisitions) => draftRequisitions?.map(requisition => produce(requisition, (draftRequisition) => {
                draftRequisition.products = draftRequisition?.products?.map(requisitionProduct => produce(requisitionProduct, (draftRequisitionProduct) => {
                    draftRequisitionProduct.main_product = get().transformProduct(get().buildInitialProductState(draftRequisitionProduct.main_product), draftRequisition)
                    draftRequisitionProduct.alternative_products = draftRequisitionProduct.alternative_products.map(alternative => get().transformProduct(get().buildInitialProductAlternativeState(alternative, draftRequisitionProduct.main_product.id), draftRequisition))
                    return draftRequisitionProduct
                })) ?? []
            })))
            const draftRequisitions = getDraftRequisitions(draftState.requisitions)
            draftState.numberOfDraftRequisitions = draftRequisitions.length ?? 0
            draftState.draftRequisitions = draftRequisitions ?? []
            draftState.requisitionPopupStatus = draftRequisitions.length == 0 ? RequisitionPopupStatus.createRequisition : RequisitionPopupStatus.selectRequisition
        }))
        api.subscribe(
            state => {
                const currentRequisition = state.currentRequisition
                if (JSON.stringify(currentRequisition) !== JSON.stringify(get().copyOfCurrentRequisition))
                    set(produce(draftState => {
                        draftState.numberOfCurrentMainProducts = currentRequisition ? draftState.currentRequisition.products.length : 0
                        draftState.copyOfCurrentRequisition = currentRequisition
                    }))
            },
        )
    },
    createRequisition: (projectId: string, projectName: string, handleFailure: (error: string) => void, product?: Product, goToDraftRequisition?: (requisitionId: number) => void) => {
        const authenticationStatus = useUserData.getState().authenticationStatus
        let existedProjectName = ''
        if (projectId != '')
            existedProjectName = get().projects?.find(e => e.id == projectId).name
        // else existedProjectName = get().projects?.find(e => e.name == projectName)?.name
        useCreateRequisition({ product_id: product?.id, quantity: product?.quantity, project_id: projectId, project_name: existedProjectName, new_project_name: projectName }, product, authenticationStatus).then(data => {
            get().setCurrentRequisition(data.data, true)
            set(produce(draftState => {
                draftState.requisitions.push(data.data)
                const draftRequisitions = getDraftRequisitions(draftState.requisitions)
                draftState.persisted_requisitions.push(data.data)
                draftState.draftRequisitions = draftRequisitions ?? []
                draftState.numberOfDraftRequisitions = draftRequisitions.length ?? 0
                draftState.isRequisitionPopupOpened = false
            }))
            // if (!existedProjectName) get().addProject(data.data.project)
            if (!product && goToDraftRequisition) {
                goToDraftRequisition(data.data)
            }
        }).catch(error => {
            handleFailure(error.data.error)
        })
    },
    deleteCurrentRequisition: () => {
        const currentRequisition = get().currentRequisition
        get().deleteRequisitionFromRequisitions(currentRequisition, set(produce(draftState => { draftState.currentRequisition = null })))
    },
    closeRequisitionPopup: () => set({ isRequisitionPopupOpened: false }),
    openRequisitionPopup: (requisitionPopupStatus: RequisitionPopupStatus) => set(produce(draftState => {
        draftState.isRequisitionPopupOpened = true
        draftState.requisitionPopupStatus = requisitionPopupStatus
    })
    ),
    deleteRequisitionFromRequisitions: (requisition: Requisition, handleDeleteRequisition: () => void) => {
        deleteRequisition(requisition).then(data => {
            set(produce(draftState => {
                const draftRequisitions = getDraftRequisitions(draftState.requisitions)
                draftState.requisitions = draftState.requisitions.filter(element => element.id !== requisition.id)
                draftState.numberOfDraftRequisitions = draftRequisitions.length ?? 0
                draftState.draftRequisitions = draftRequisitions ?? []
            }))
            handleDeleteRequisition()
        }).catch(e => {
        })
    },
    setRequisitionPopupStatus: (requisitionPopupStatus: RequisitionPopupStatus) => set({ requisitionPopupStatus: requisitionPopupStatus }),
    transformProduct: (product: Product, requisition: Requisition) => {
        const parentId = product.parentId
        const findMainProduct = requisition.products.find(element => element.main_product.id === (parentId ?? product.id))
        if (!findMainProduct) {
            return produce(product, (draftProduct) => {
                draftProduct.inCart = false
                draftProduct.quantity = 1
                draftProduct.additional_requirements = ''
            })
        }

        if (parentId) {
            const findAlternativeProduct = findMainProduct.alternative_products.find(element => element.id === product.id)
            if (!findAlternativeProduct) {
                return produce(product, (draftProduct) => {
                    draftProduct.inCart = false
                })
            }
            return produce(product, (draftProduct) => {
                draftProduct.inCart = true
                draftProduct.inCartType = 'alternative'
            })
        } else {
            return produce(product, (draftProduct) => {
                draftProduct.inCart = true
                draftProduct.inCartType = 'main'
                draftProduct.quantity = findMainProduct.quantity
                draftProduct.additional_requirements = findMainProduct.additional_requirements
            })
        }
    },
    buildInitialProductState: (product: Product) => {
        return produce(product, (draftProduct) => {
            draftProduct.inCartType = 'main'
            draftProduct.inCart = false
            draftProduct.quantity = 1
            draftProduct.price = product?.price ?? product?.catalog_price ?? ''
            draftProduct.additional_requirements = ''
            draftProduct.parentId = null
        })
    },
    buildInitialProductAlternativeState: (product: Product, mainProductId: string) => {
        return produce(product, (draftProduct) => {
            draftProduct.inCartType = 'alternative'
            draftProduct.inCart = false
            draftProduct.parentId = mainProductId
        })
    },
    editAndRegenerateRFQ: (requisitionId: number, handleEditAndRegenerateRFQ: (data: Requisition) => void) => {
        useRegenerateRFQFromRequisition(requisitionId).then((data) => {
            handleEditAndRegenerateRFQ(data.data)
            get().requisitionSliceActions.refreshRequisition(data.data)
        })
    },
    requisitionSliceActions: {
        refreshRequisition: (refreshedRequisition: Requisition) => {
            const requisitions = get().requisitions.map(requisition => {
                if (requisition.id == refreshedRequisition.id) return refreshedRequisition
                else return requisition
            })
            get().setRequisitions(requisitions)
        },
        saveFormProducts: (requisition: Requisition, products: Product[], handleSavingSucess: (data: ProcurementModelDetails) => void, onError?: () => void) => {
            saveRequisition(requisition).then(data => {
                const formDetails = data.data as ProcurementModelDetails
                handleSavingSucess({ ...formDetails, products: get().requisitionSliceActions.getProductsArrayFromRequisition(formDetails) })
            }).catch(() => {
                onError?.()
            })
        },
        setProducts: (requisitionId: number, products: Product[]) => {
            const formattedProducts = products.map(({ quantity, alternatives, additional_requirements, ...product }) => ({
                quantity,
                alternative_products: alternatives ?? [],
                main_product: product,
                additional_requirements
            }))

            const newRequisitions = get().requisitions.map(req => req.id === requisitionId ? { ...req, products: formattedProducts } : req);
            set(produce(draftState => {
                draftState.requisitions = newRequisitions
            }))
        },
        getProductsArrayFromRequisition: (requisition: Requisition) => {
            const products = requisition.products.map(requisitionProduct => {
                const mainproduct = get().transformProduct(requisitionProduct.main_product, requisition)
                const alternatives = requisitionProduct.alternative_products.map(alternative => get().transformProduct(alternative, requisition))
                return { ...mainproduct, alternatives: alternatives ?? [] }
            }).flat()
            return [...products]
        },
        fetchDetails: (formId: string, handlerAfterFetch: (requisition: Requisition) => void) => {
            const authenticationStatus = useUserData.getState().authenticationStatus
            // set(produce(draftState => {
            //     draftState.requisitionDetailsSliceState.loadingDraftRequistion = true
            // }))
            set(produce(draftState => {
                draftState.requisitionDetailsSliceState.fetchRequisitionDetailsQueryStatus = QueryHandler.getSuccessStatus();
            }))
            if (authenticationStatus == AuthenticateStatus.AUTHENTICATED)
                getRquisitionDetails(formId).then(data => {
                    handlerAfterFetch({ ...data.data, products: get().requisitionSliceActions.getProductsArrayFromRequisition(data.data) })
                    set(produce(draftState => {
                        draftState.requisitionDetailsSliceState.fetchRequisitionDetailsQueryStatus = QueryHandler.getSuccessStatus();
                    }))
                }).catch((response) => {
                    set(produce(draftState => {
                        draftState.requisitionDetailsSliceState.fetchRequisitionDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                    }))
                })
            else {
                const requisition = get().requisitions.find(e => e.id == formId)
                handlerAfterFetch({ ...requisition, products: get().requisitionSliceActions.getProductsArrayFromRequisition(requisition) })
            }
        },
        handleSelectForm: (values: { product: Product, requisitionId: number }, handlerAfterFetch: (requisition: Requisition) => void) => {
            get().requisitionSliceActions.fetchDetails(values.requisitionId, handlerAfterFetch)
        },
        create: (values: { projectId: string, projectName: string }, handlerAfterCreate: (data: ProcurementModelDetails) => void, onError?: () => void) => {
            const authenticationStatus = useUserData.getState().authenticationStatus
            let existedProjectName = ''
            if (values.projectId != '')
                existedProjectName = get().projects?.find(e => e.id == values.projectId).name
            useCreateRequisition({ project_id: values.projectId, project_name: existedProjectName, new_project_name: values.projectName }, undefined, authenticationStatus, get().requisitions?.length).then(data => {
                handlerAfterCreate(data.data)
                if(get().requisitions.find(req => req.id === data.data.id) === undefined) {
                    set(produce(draftState => { draftState.requisitions.push(data.data) }))
                }
                // if (!existedProjectName) get().addProject(data.data.project)
            }).catch(() => {
                onError?.()
            })
        },
    },
    changeProject: (project: GeneralObject) => {
        const modelAfterUpdateProject = useChangeProject(project, get().currentRequisition, false, true)
        get().setCurrentRequisition(modelAfterUpdateProject)
    },
    saveRequisition: (requisition: Requisition) => {
        get().setCustomLoadingButton(ProcurementButtons.SAVE_REQUISITION_UPDATES)
        saveRequisition(requisition).then((data) => {
            set(produce(draftState => {
                draftState.requisitions = draftState.requisitions.map(item => item.id == requisition.id ? data.data : item)
                draftState.persisted_requisitions = draftState.persisted_requisitions.map(item => item.id == requisition.id ? data.data : item)
                draftState.currentRequisitionHasChangesToSave = false
                draftState.currentRequisition = data.data
                draftState.customLoadingButton = false
            }))
            get().setCurrentRequisition(data.data)
        })
    },
    cancelAddingItemsToRequisition: () => {
        const currentRequisition = get().currentRequisition
        const persistedRequisition = get().persisted_requisitions.find((requisition) => requisition.id == currentRequisition?.id)
        const allRequisitions = get().requisitions.map(function (requisition) {
            if (requisition.id == currentRequisition?.id)
                return persistedRequisition
            return requisition
        })
        get().setRequisitions(allRequisitions)
        get().setCurrentRequisition(null)
        set(produce(draftState => {
            draftState.currentRequisitionHasChangesToSave = false
        }))
    },
    markRequisitionHasNewChanges: () => {
        const currentRequisition = get().currentRequisition
        const persistedRequisition = get().persisted_requisitions.find((requisition) => requisition.id == currentRequisition?.id)
        set(produce(draftState => {
            // draftState.currentRequisitionHasChangesToSave = JSON.stringify(currentRequisition?.products) !== JSON.stringify(persistedRequisition.products)
            draftState.currentRequisitionHasChangesToSave = !compareArraysOfRequisitionProducts(currentRequisition?.products ?? [], persistedRequisition?.products ?? [])
        }))
    },
    setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
        set(produce(draftState => {
            draftState.customLoadingButton = customButton
            if (!customButton) { draftState.customLoadingButton = null }
        }))
    },
})

const getDraftRequisitions = (requisitions: Requisition[]) => {
    const userId = useUserData.getState().userInfo?.id
    return requisitions?.filter(requisition => requisition.status == RequisitionStatus.DRAFT && requisition.owner_id == userId) ?? []
}

function compareArraysOfRequisitionProducts(arr1: RequisitionProduct[], arr2: RequisitionProduct[]): boolean {

    if (arr1.length !== arr2.length) {
        return false;
    }


    // const map1 = new Map<string, RequisitionProduct>(arr1.map(requisitionProduct => [getRequisitionProductKey(requisitionProduct), requisitionProduct]));
    const map2 = new Map<string, RequisitionProduct>(arr2.map(requisitionProduct => [getRequisitionProductKey(requisitionProduct), requisitionProduct]));

    for (const requisitionProduct of arr1) {
        if (!map2.has(getRequisitionProductKey(requisitionProduct)))
            return false

        let secondRequisitionProduct = map2.get(getRequisitionProductKey(requisitionProduct))
        if (!compareArraysOfProducts(requisitionProduct?.alternative_products ?? [], secondRequisitionProduct?.alternative_products ?? []))
            return false
    }

    return true;
}

function compareArraysOfProducts(arr1: Product[], arr2: Product[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const set1 = new Set<string>(arr1.map(getProductKey));
    const set2 = new Set<string>(arr2.map(getProductKey));

    const eqSet = (firstSet: Set<string>, secondSet: Set<string>) =>
        [...firstSet].every((x) => secondSet.has(x));

    return eqSet(set1, set2)
}

function getRequisitionProductKey(requisitionProduct: RequisitionProduct): string {
    return `${requisitionProduct.quantity}-${getProductKey(requisitionProduct.main_product)}`;
}

function getProductKey(product: Product): string {
    return `${product.id}`;
}

// export function createDraftRequisitionObject(id, ) {
//      return {
//          id: 1,
//          name: name,
//          products: [],
//          status: RequisitionStatus.DRAFT,
//          created_at: '19/12/2022',
//          project: {
//              id: 1,
//              name: projectName,
//          },
//          bundle_rfq_id: null
//      }
// }