import { SvgIcon } from "@mui/material";

export const DirectoryIcon = () => (
    <SvgIcon width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_40851_272522" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
            <rect y="0.109375" width="16" height="15.9301" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_40851_272522)">
            <path d="M1.9987 13.7169C1.80981 13.7169 1.65148 13.6533 1.5237 13.5261C1.39592 13.3989 1.33203 13.2413 1.33203 13.0532V9.07066C1.33203 8.88259 1.39592 8.72495 1.5237 8.59773C1.65148 8.47051 1.80981 8.4069 1.9987 8.4069H5.9987C6.18759 8.4069 6.34592 8.47051 6.4737 8.59773C6.60148 8.72495 6.66537 8.88259 6.66537 9.07066V13.0532C6.66537 13.2413 6.60148 13.3989 6.4737 13.5261C6.34592 13.6533 6.18759 13.7169 5.9987 13.7169H1.9987ZM9.81537 6.74751H4.8487C4.59314 6.74751 4.40148 6.63412 4.2737 6.40734C4.14592 6.18055 4.1487 5.95654 4.28203 5.73529L6.76537 1.70297C6.8987 1.48172 7.08759 1.37109 7.33203 1.37109C7.57648 1.37109 7.76537 1.48172 7.8987 1.70297L10.382 5.73529C10.5154 5.95654 10.5181 6.18055 10.3904 6.40734C10.2626 6.63412 10.0709 6.74751 9.81537 6.74751ZM13.9154 14.8785L12.6154 13.5842C12.382 13.7391 12.1293 13.8552 11.857 13.9327C11.5848 14.0101 11.2987 14.0488 10.9987 14.0488C10.1654 14.0488 9.45703 13.7584 8.8737 13.1776C8.29037 12.5969 7.9987 11.8916 7.9987 11.0619C7.9987 10.2322 8.29037 9.52699 8.8737 8.9462C9.45703 8.36542 10.1654 8.07502 10.9987 8.07502C11.832 8.07502 12.5404 8.36542 13.1237 8.9462C13.707 9.52699 13.9987 10.2322 13.9987 11.0619C13.9987 11.3496 13.9598 11.6289 13.882 11.8999C13.8043 12.1709 13.6931 12.4226 13.5487 12.6549L14.8487 13.9493C14.9709 14.0709 15.032 14.2258 15.032 14.4139C15.032 14.602 14.9709 14.7568 14.8487 14.8785C14.7265 15.0002 14.5709 15.061 14.382 15.061C14.1931 15.061 14.0376 15.0002 13.9154 14.8785ZM10.9987 12.7213C11.4654 12.7213 11.8598 12.5609 12.182 12.2401C12.5043 11.9193 12.6654 11.5266 12.6654 11.0619C12.6654 10.5973 12.5043 10.2046 12.182 9.88376C11.8598 9.56294 11.4654 9.40253 10.9987 9.40253C10.532 9.40253 10.1376 9.56294 9.81537 9.88376C9.49314 10.2046 9.33203 10.5973 9.33203 11.0619C9.33203 11.5266 9.49314 11.9193 9.81537 12.2401C10.1376 12.5609 10.532 12.7213 10.9987 12.7213Z" fill="#0074FF" />
        </g>
    </SvgIcon>
);
