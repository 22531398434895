import { ProductManagerPopupStatus } from '@/config';
import { useProcurementStore } from '@/zustand';
import { Box } from '@mui/material';
import React from 'react';
import { DescPopup } from 'src/ui/components/shared';
import AddToExistingQuote from './add-to-existing-quote';
import CreateQuoteWithProduct from './create-quote-with-product';

type PopupContentType = 'CREATE_NEW' | 'ADD_TO_EXISTING';

export const QuoteEnvironmentPopupManager = () => {
  const [popupContent, setPopupContent] = React.useState<PopupContentType>('CREATE_NEW');
  const handleTogglePopupContent = React.useCallback(() => {
    setPopupContent(prev => (prev === 'CREATE_NEW' ? 'ADD_TO_EXISTING' : 'CREATE_NEW'));
  }, []);

  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const isOpen = data.productManagerPopupStatus === ProductManagerPopupStatus.CREATE_QUOTE;

  React.useEffect(() => {
    if (isOpen) {
      setPopupContent('CREATE_NEW');
    }
  }, [isOpen]);

  const closePopup = () => {
    actions.setProductManagerPopupStatus(null);
    actions.setCandidateSellerRelatedToForm(null);
    actions.setCandidateProductToAdd(null);
  };

  return (
    <DescPopup open={isOpen} handleClose={closePopup}>
      <Box width={'100%'} px={1}>
        <>
          {popupContent === 'CREATE_NEW' && (
            <CreateQuoteWithProduct onPopupSwitch={handleTogglePopupContent} onPopupClose={closePopup} />
          )}
          {popupContent === 'ADD_TO_EXISTING' && (
            <AddToExistingQuote onPopupSwitch={handleTogglePopupContent} onPopupClose={closePopup} />
          )}
        </>
      </Box>
    </DescPopup>
  );
};
export default QuoteEnvironmentPopupManager;
