import { request } from '@/helpers';
import { ProcurementFormModel } from '@/models';
import { useInfiniteQuery } from '@tanstack/react-query';
import { PaginatedResponse } from 'src/logic/models/queries';

export const useGetRelatedPurchaseOrders = ({ enabled = true, search = '', seller_id = '', product_id = '' } = {}) => {
  return useInfiniteQuery<PaginatedResponse<ProcurementFormModel>>({
    queryKey: ['RELATED_PURCHASE_ORDERS', { search, seller_id, product_id }],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await request({
        url: '/buyer/purchase_order/index-for-product',
        method: 'GET',
        params: {
          page: pageParam,
          query: search,
          product_id,
          seller_id,
        },
      });
      return response.data;
    },
    retry: 0,
    refetchOnMount: true,
    enabled,
    getNextPageParam: lastPage => {
      const { current_page, last_page } = lastPage.meta;
      if (current_page === last_page) {
        return undefined;
      }
      return current_page + 1;
    },
  });
};
