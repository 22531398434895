import { StyledPopper } from '@/components';
import HistoryIcon from '@mui/icons-material/History';
import { Divider, Grid, SxProps, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
export enum HistoryType {
    purchase = 'PURCHASE',
    sales = 'SALES'
}
type ProductHistoryProps = {
    items: any[]
    title: string
    type: HistoryType
    iconText?: React.ReactNode
    iconColor?: string;
    iconSx?: SxProps
};

export const ProductHistory: React.VFC<ProductHistoryProps> = ({ items, title, type, iconText, iconColor = '#518FBC', iconSx = {} }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClosePopper = () => setAnchorEl(null)

    return (
        <>
            <span onClick={(e) => { e.stopPropagation(); setAnchorEl(anchorEl ? null : e.currentTarget); }} style={{cursor:'pointer'}}>
                <HistoryIcon sx={{ fontSize: '20px !important', ml: 1.5, color: iconColor, ...iconSx }}  />
                {iconText}
            </span>

            <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper} zIndex={1300}>
                <Grid container md={12} p={2} width={450}>
                    <HistoryIcon sx={{ fontSize: '22px !important', color: 'primary.main' }} />
                    <Typography variant='h6' color='primary.main' fontWeight={700} pl={1}>{title}</Typography>
                    <Grid container md={12} pb={1} pt={2} px={1} sx={{ maxHeight: '300px', overflowY: 'auto' }} className='styled-scrollbar' onMouseDown={(e) => e.stopPropagation()}>
                        {items?.map((item: any) => {
                            return (
                                <>
                                    {type === HistoryType.purchase ? <PurchaseHistoryItem item={item} />
                                        : type === HistoryType.sales ? <SalesHistoryItem item={item} />
                                            : <></>}
                                </>
                            )
                        })
                        }
                    </Grid>
                </Grid>
            </StyledPopper>
        </>
    )
}

export const PurchaseHistoryItem: FC<{ item: any }> = ({ item }) => {
    return (
        <Grid container md={12}>
            <Grid item container md={12} justifyContent={'space-between'} alignItems={'center'} component={Typography}>
                <Typography variant='body2' color='primary.main' fontWeight={500} sx={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {item.purchase_order_name}
                </Typography>
                <Typography variant='body2' color='text.disabled' fontWeight={400}>{t('texts.aed')} {item.price}</Typography>
            </Grid>
            <Grid item container md={12} alignItems={'center'}>
                <Typography variant='subtitle2' color='text.third' fontWeight={400}>
                    {(() => {
                        const [day, month, year] = item.date.split("-");
                        const formattedDate = new Date(`${month}/${day}/${year}`).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric" });
                        return formattedDate;
                    })()}
                </Typography>
                <Divider variant='middle' orientation='vertical' sx={{ mx: 1, }} />
                <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {item.seller_name}
                </Typography>
                <Divider variant='middle' orientation='vertical' sx={{ mx: 1 }} />
                <Typography variant='subtitle2' color='text.third' fontWeight={400}>{t('texts.qty')}: {item.quantity}</Typography>
            </Grid>
            <Grid item md={12} pb={1} pt={2}>
                <Divider sx={{ opacity: 0.7 }} />
            </Grid>
        </Grid>
    )
}
export const SalesHistoryItem: FC<{ item: any }> = ({ item }) => {
    return (
        <Grid container md={12}>
            <Grid item container md={12} justifyContent={'space-between'} alignItems={'center'} component={Typography}>
                <Typography variant='body2' color='primary.main' fontWeight={500} sx={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {item.quote_name}
                </Typography>
                <Typography variant='body2' color='text.disabled' fontWeight={400}>{t('texts.aed')} {item.price}</Typography>
            </Grid>
            <Grid item container md={12} alignItems={'center'}>
                <Typography variant='subtitle2' color='text.third' fontWeight={400}>
                    {(() => {
                        const [day, month, year] = item.date.split("-");
                        const formattedDate = new Date(`${month}/${day}/${year}`).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric" });
                        return formattedDate;
                    })()}
                </Typography>
                <Divider variant='middle' orientation='vertical' sx={{ mx: 1, }} />
                <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {item.buyer_name}
                </Typography>
                <Divider variant='middle' orientation='vertical' sx={{ mx: 1 }} />
                <Typography variant='subtitle2' color='text.third' fontWeight={400}>{t('texts.qty')}: {item.quantity}</Typography>
            </Grid>
            <Grid item md={12} pb={1} pt={2}>
                <Divider sx={{ opacity: 0.7 }} />
            </Grid>
        </Grid>
    )
}