import { procurementStatus, ProductInCartType } from '@/config';
import { GeneralObject } from '../procurement';

export type FilterItem = {
  id: number;
  name: string;
  is_selected: boolean;
};

export type Filter = {
  title: string;
  formName: string;
  items: FilterItem[];
};

export const filtrationPurpose = {
  QUOTE: 'SELLER_ADD_TO_QUOTE',
  EDIT_QUOTE_FROM_SCRATCH: 'SELLER_ADD_TO_QUOTE',
  REQUISITION: null,
  CATALOG: null,
  PURCHASE_ORDER: null,
  EDIT_PO_FROM_SCRATCH: null,
};

export type Seller = {
  seller: {
    id: string;
    name: string;
  };
  unit_price: string;
  is_private?: boolean;
  quantity?: number;
  price?: string;
  can_buyer_unlink?: boolean
};

export type ProductCategoryKindType = 1 | 2;
export const ProductCategoryKind = {
  PRODUCT: 1,
  SERVICE: 2,
} as const;

export const ProductCategoryKindLabel = {
  1: 'Product',
  2: 'Service',
} as const;

export type ProductDocumentType = {
  id: number;
  name: string;
  url: string;
  size: number;
};

export type Product = {
  category_kind: ProductCategoryKindType;
  id: number;
  name: string;
  part_number: string;
  brandName: string;
  description: string;
  partNumber: string;
  image_url: string;
  // type: string;
  productAttributes: { name: string; value: string }[];
  category: { id: string; name: string; parent?: GeneralObject };
  categoryClass: GeneralObject;
  categoryType: GeneralObject;
  brand: { id: string; name: string; image_url: string };
  quantity?: number;
  inCart: boolean;
  inCartType: ProductInCartType | null;
  parentId: number | null;
  price?: string;
  sellers?: Seller[];
  is_active?: boolean;
  part_of_seller_catalog?: boolean;
  part_of_draft_list?: null | { id: number; name: string };
  catalog_price?: string;
  group?: { id: number; name: string };
  cat?: { id: number; name: string };
  class?: { id: number; name: string };
  type?: string | { id: number; name: string };
  is_private_product?: boolean | undefined;
  can_edit_product_details?: boolean | undefined;
  documents?: ProductDocumentType[];
  download_url?: string;
  catalog_item?: {
    id: number,
    name: string,
    price: number | null,
    is_active: boolean,
    currently_in_use: boolean,
    is_private: boolean,
    status: procurementStatus,
    can_unlink: boolean
    is_published: boolean,
  }
};

export type Filters_Res = {
  title: string;
  type: string;
  filter_items: FilterItem[];
};

export type Filters_Req = {
  category_ids: number[];
  class_ids: number[];
  type_ids: number[];
  power_source_ids: number[];
  application_ids: number[];
  brand_ids: number[];
};

export type sortType = {
  form_name: string;
  id: string;
  parent_id: string;
  title: string;
  filter_items: FilterItem[];
};

export type Filters = {
  data: Product[];
  filter_data: Filters_Res;
  sort_data: sortType;
};

export type SelectedFilters = {
  type: string;
  ids: number[];
};

export type Alternative_Res = {
  data: Product[];
  filter_data: Filters_Res;
  product: Product;
};

export type PaginationResult = {
  total: number;
  last_page: number;
  current_page: number;
};
