import { apiRoutes } from '@/config';
import { request } from '@/helpers';
import { Quote } from '@/models';
import { useInfiniteQuery } from '@tanstack/react-query';
import { PaginatedResponse } from 'src/logic/models/queries';

export const useGetEditableQuotes = () => {
  return useInfiniteQuery<PaginatedResponse<Quote>>({
    queryKey: ['EDITABLE_QUOTES'],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await request({
        url: apiRoutes.indexEditableQuotes,
        method: 'GET',
        params: {
          page: pageParam,
        },
      });
      return response.data;
    },
    retry: 0,
    refetchOnMount: true,
    getNextPageParam: lastPage => {
      const { current_page, last_page } = lastPage.meta;
      if (current_page === last_page) {
        return undefined;
      }
      return current_page + 1;
    },
  });
};
