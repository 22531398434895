import { Loading, RadioButtonGroup, SearchTextField } from '@/components';
import { AuthenticateStatus, productManagerEnv } from '@/config';
import { useDebounce } from '@/hooks';
import { Product, Requisition } from '@/models';
import { NotifySuccess } from '@/services';
import { useProcurementStore, useUserData } from '@/zustand';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import { useGetEditableRequisitions } from 'src/logic/services/requisitions/queries';

const useRequisitions = ({ search = '' } = {}) => {
  const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
  const { data: requisitionsData, ...rest } = useGetEditableRequisitions({ enabled: !isGuest, search });
  const { requisitions: cachedRequisitions } = useProcurementStore();

  const requisitions = React.useMemo(() => {
    if (isGuest)
      return (
        cachedRequisitions?.filter?.(req => req.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) ?? []
      );
    if (requisitionsData) {
      return requisitionsData.pages.flatMap(page => page.data);
    }
    return [];
  }, [cachedRequisitions, requisitionsData, search, isGuest]);

  return { requisitions, ...rest };
};

export const SelectRequisitionPoppup = (product: Product & { onClose: () => void }) => {
  const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
  const { onClose } = product;
  const { t } = useTranslation();
  const [searchText, setsearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  useDebounce(() => setDebouncedSearchText(searchText), 700, [searchText]);

  const [highlitedRequisitionId, setHighlitedRequisitionId] = useState('');

  const { requisitions, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useRequisitions({
    search: debouncedSearchText,
  });

  const { requisitionSliceActions } = useProcurementStore();
  const { actions } = useProcurementStore(state => state.productFormManagerProperties);
  const handleChangeRequisition = (requisitionId: string) => setHighlitedRequisitionId(requisitionId);
  const addToRequisition = () =>
    actions.selectForm(
      { requisitionId: highlitedRequisitionId, product: product },
      'requisitionSliceActions',
      productManagerEnv.requisition,
      requisitions
    );

  const createNewRequisitionLoading = useLoadingState();
  const { getProductsArrayFromRequisition } = useProcurementStore(state => state.requisitionSliceActions);
  const { setDetails, addProduct } = useProcurementStore(state => state.productFormManagerProperties.actions);

  const handleCreateRequisition = () => {
    if(createNewRequisitionLoading.isLoading) return;
    
    createNewRequisitionLoading.startLoading();
    requisitionSliceActions.create(
      { projectId: '', projectName: '' },
      req => {
        setDetails({ ...req, products: getProductsArrayFromRequisition(req) }, productManagerEnv.requisition);
        addProduct(product);
        createNewRequisitionLoading.finishLoading();
        // NotifySuccess('Requisition Created');
        NotifySuccess(t('notifications.create_form', { formName: req.name }));
        onClose();
      },
      createNewRequisitionLoading.finishLoading
    );
  };

  React.useEffect(() => {
    if (debouncedSearchText || requisitions?.length > 0) {
      return;
    }
    if (isGuest) {
      handleCreateRequisition();
      return;
    }
    if (!isLoading) {
      handleCreateRequisition();
      return;
    }
  }, [isLoading, requisitions, debouncedSearchText, isGuest]);

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.();
      }
    },
  });

  return (
    <Stack>
      <Typography color='primary' variant='overline' fontWeight='700' mt={1}>
        {t('buttons.add_to_requisition')}
      </Typography>
      <Typography variant='body2' color='text.disabled'>
        {t('texts.select_requisition')}
      </Typography>
      <Box my={2} height={40}>
        <SearchTextField
          translateKey='search_draft_requisitions'
          value={searchText}
          onChange={e => setsearchText(e.target.value)}
        />
      </Box>
      {isLoading && !isGuest ? (
        <Stack height={'300px'}>
          <Loading fullHeight={false} />
        </Stack>
      ) : (
        <>
          <Stack mb={2} height='220px' ref={containerRef}>
            <Stack pl={1}>
              <RadioButtonGroup
                data={requisitions}
                handleChange={handleChangeRequisition}
                value={highlitedRequisitionId}
                getSubTitle={(requisition: Requisition) => {
                  let numberOfItems = requisition?.products?.length ?? 0;

                  const productsCount = requisition?.number_of_products;
                  const servicesCount = requisition?.number_of_services;
                  if (typeof productsCount === 'number' && typeof servicesCount === 'number') {
                    numberOfItems = productsCount + servicesCount;
                  }

                  return (
                    numberOfItems + ' products/services, ' + requisition.project.name ?? `0  ${t('texts.products')}`
                  );
                }}
              />
              {hasNextPage && (
                <div
                  style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
                  ref={observerRef}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.5rem' }}>
                    <CircularProgress size={'1rem'} />
                    <Typography color='#8B8D98' fontWeight={400} fontSize={13}>
                      Loading Requisitions...
                    </Typography>
                  </div>
                </div>
              )}
            </Stack>
          </Stack>
          <Button
            type='button'
            variant='contained'
            onClick={addToRequisition}
            disabled={createNewRequisitionLoading.isLoading || !highlitedRequisitionId}
          >
            {t('buttons.select_draft_requisition')}
          </Button>
          {t('texts.or')}
          <LoadingButton
            type='button'
            loading={createNewRequisitionLoading.isLoading}
            variant='outlined'
            onClick={handleCreateRequisition}
          >
            {t('buttons.create_new_requisition')}
          </LoadingButton>
        </>
      )}
    </Stack>
  );
};
