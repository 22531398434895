import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { TermType } from 'src/logic/zustand/procurement/shared/createGeneralTermsSlice';
import { containerStyles, gridItemStyles } from '../styles';
import { EditableGeneralTermsProps } from '../types';
import SelectTerms from './SelectTerms';

const EditableGeneralTerms = (props: EditableGeneralTermsProps) => {
  const { properties, actions, helpers } = props.generalTermsSlice.generalTerms;
  const { draft, payment_terms, warranty_terms, delivery_terms, isLoading } = properties;
  const { getItemsToRender } = helpers;
  const {
    changeSelectedOption,
    changeSliderValue,
    changeTextFieldValue,
    saveTerms,
    getTermsLabel,
    isGeneralTermsValid,
    isTermsValid,
  } = actions;

  const forceValidateTerms = props.forceValidateTerms ?? false;
  const isValid = React.useMemo(() => {
    if (!forceValidateTerms) return true;
    return isGeneralTermsValid();
  }, [forceValidateTerms, payment_terms, warranty_terms, delivery_terms]);

  const shouldShowErrorIndicator = !isValid;

  const paymentTermsItemToRender = useMemo(() => getItemsToRender(draft.payment_terms), [draft.payment_terms]);
  const warrantyTermsItemToRender = useMemo(() => getItemsToRender(draft.warranty_terms), [draft.warranty_terms]);
  const deliveryTermsItemToRender = useMemo(() => getItemsToRender(draft.delivery_terms), [draft.delivery_terms]);

  const canSavePaymentTerms = useMemo(() => helpers.isTermsValid(draft.payment_terms), [draft.payment_terms]);
  const canSaveWarrantyTerms = useMemo(() => helpers.isTermsValid(draft.warranty_terms), [draft.warranty_terms]);
  const canSaveDeliveryTerms = useMemo(() => helpers.isTermsValid(draft.delivery_terms), [draft.delivery_terms]);

  const paymentTermValueAsLabel = useMemo(() => getTermsLabel('payment_terms'), [payment_terms]);
  const warrantyTermValueAsLabel = useMemo(() => getTermsLabel('warranty_terms'), [warranty_terms]);
  const deliveryTermValueAsLabel = useMemo(() => getTermsLabel('delivery_terms'), [delivery_terms]);

  const paymentTermsIsValid = useMemo(() => isTermsValid('payment_terms'), [forceValidateTerms, payment_terms]);
  const warrantyTermsIsValid = useMemo(() => isTermsValid('warranty_terms'), [forceValidateTerms, warranty_terms]);
  const deliveryTermsIsValid = useMemo(() => isTermsValid('delivery_terms'), [forceValidateTerms, delivery_terms]);

  const handleSave = (termType: TermType) => {
    const isSaved = saveTerms(termType);
    if (isSaved && isGeneralTermsValid()) {
      props.handleSelectGeneralTerms(actions.getSelectedGeneralTerms());
    }
    return isSaved;
  };

  return (
    <Grid
      container
      style={shouldShowErrorIndicator ? { ...containerStyles, border: '1px solid red' } : containerStyles}
    >
      <Grid item md={2.25} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography color='#3A3E50' fontWeight={700} fontSize={14}>
          General Terms
        </Typography>
        {isLoading && <CircularProgress size={'1rem'} sx={{ ml: 1 }} />}
      </Grid>

      <Grid item md={3.25} style={gridItemStyles}>
        <SelectTerms
          label='Payment'
          popupLabel='Payment Terms'
          terms={paymentTermsItemToRender}
          onOptionChange={(...params) => changeSelectedOption('payment_terms', ...params)}
          onSliderValueChange={(...params) => changeSliderValue('payment_terms', ...params)}
          onTextFieldValueChange={(...params) => changeTextFieldValue('payment_terms', ...params)}
          onSave={() => handleSave('payment_terms')}
          selectedValueLabel={paymentTermValueAsLabel}
          shouldShowErrorIndicator={!paymentTermsIsValid && forceValidateTerms}
          canSave={canSavePaymentTerms}
          onPopupOpen={() => actions.cloneTermsToDraftTerms('payment_terms')}
          saveOnClose
        />
      </Grid>
      <Grid item md={3.25} style={gridItemStyles}>
        <SelectTerms
          label='Warranty'
          popupLabel='Warranty Terms'
          terms={warrantyTermsItemToRender}
          onOptionChange={(...params) => changeSelectedOption('warranty_terms', ...params)}
          onSliderValueChange={(...params) => changeSliderValue('warranty_terms', ...params)}
          onTextFieldValueChange={(...params) => changeTextFieldValue('warranty_terms', ...params)}
          onSave={() => handleSave('warranty_terms')}
          selectedValueLabel={warrantyTermValueAsLabel}
          shouldShowErrorIndicator={!warrantyTermsIsValid && forceValidateTerms}
          canSave={canSaveWarrantyTerms}
          onPopupOpen={() => actions.cloneTermsToDraftTerms('warranty_terms')}
          saveOnClose
        />
      </Grid>
      <Grid item md={3.25} style={gridItemStyles}>
        <SelectTerms
          label='Delivery'
          popupLabel='Delivery Terms'
          terms={deliveryTermsItemToRender}
          onOptionChange={(...params) => changeSelectedOption('delivery_terms', ...params)}
          onSliderValueChange={(...params) => changeSliderValue('delivery_terms', ...params)}
          onTextFieldValueChange={(...params) => changeTextFieldValue('delivery_terms', ...params)}
          onSave={() => handleSave('delivery_terms')}
          selectedValueLabel={deliveryTermValueAsLabel}
          shouldShowErrorIndicator={!deliveryTermsIsValid && forceValidateTerms}
          onPopupOpen={() => actions.cloneTermsToDraftTerms('delivery_terms')}
          canSave={canSaveDeliveryTerms}
          saveOnClose
        />
      </Grid>
    </Grid>
  );
};

export default EditableGeneralTerms;
