import { AuthenticateStatus, UserType } from '@/config';
import { useUserData } from '@/zustand';
import { Box, useMediaQuery } from '@mui/material';
import React, { ReactNode } from 'react';
import { Header, MobileNavbar, SideDrawer } from '..';
import { SplashScreen } from '../../shared';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';


type MainLayoutProps = {
  children: ReactNode;
  showAddNewPrivateItemLink?: boolean;
};

export const MainLayout: React.FC<MainLayoutProps> = ({ children, showAddNewPrivateItemLink }) => {
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  const authenticationStatus = useUserData(state => state.authenticationStatus);
  const companyInfo = useUserData(state => state.companyInfo);
  // const userInfo = useUserData(state => state.userInfo);
  // const hasAccessToPortal = useUserData(state => state.hasAccessToPortal);
  const { pathname } = useRouter();

  const shouldNotShowHeader = pathname.startsWith(sm.manageCatalogue.url) ||
    pathname.startsWith(sm.linkDirectory.url) || pathname === sm.catalogue.products.add.url ||
    pathname === sm.catalogue.products.editProduct('*').url

  return (
    <>
      {authenticationStatus != AuthenticateStatus.UNDEFINED ? (
        <Box>
          <Box sx={{ backgroundColor: 'background.primary.main' }}>
            {/* {authenticationStatus == AuthenticateStatus.AUTHENTICATED && <NotificationsHeader companyInfo={companyInfo} userInfo={userInfo} />} */}
            {isSmallScreen ? (
              <Box sx={{ top: 0, left: 0, right: 0, zIndex: 3, height: '60px' }} >
                <MobileNavbar authenticatedStatus={authenticationStatus} userType={authenticationStatus == AuthenticateStatus.AUTHENTICATED ? (companyInfo?.companyType) : UserType.UNDEFIENED} />
              </Box>
            ) : !shouldNotShowHeader && (
              <>
                <Header authenticatedStatus={authenticationStatus} showAddNewPrivateItemLink={showAddNewPrivateItemLink} />
              </>
            )}
          </Box>
          {!isSmallScreen ? (<SideDrawer userType={authenticationStatus == AuthenticateStatus.AUTHENTICATED ? (companyInfo?.companyType) : UserType.UNDEFIENED}>
            <main>{children}</main>
            {/* <Box component='footer'>
                <MainFooter />
                <Box my={0} />
                <BottomNavbar />
              </Box> */}
          </SideDrawer>) : (<main>{children}</main>)}
        </Box>
      ) : (
        <SplashScreen />
      )}
    </>
  );
};
