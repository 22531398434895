import { CloudIcon, DirectoryIcon } from "@/icons";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React, { useState } from "react";
import useEnvironmentFilters from "src/logic/zustand/Filters/hooks/use-environment-filters";
import { sm } from "src/site-map";

export default function AddNewListingMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const filtration_purpose = useEnvironmentFilters()?.filtration_purpose;

    const { push } = useRouter()
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                endIcon={<ExpandMoreIcon />}
                variant="contained"
                sx={{ fontWeight: 700, bgcolor: "#0074FF", color: "#fff", '&:hover': { bgcolor: "#0074FF" } }}
                onClick={handleClick}
            >
                Add New Listing
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: 4,
                        minWidth: 150,
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    },
                }}
            >

                {
                    filtration_purpose !== "SELLER_LINK_FROM_DIRECTORY" && <MenuItem onClick={handleClose}>
                        <ListItemIcon sx={{ pt: 0.5 }}>
                            <DirectoryIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant='body2' fontWeight={500} color={'#0074FF'} onClick={() => push(sm.linkDirectory.url)}> Link from Directory </Typography>
                        </ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <AddIcon fontSize="small" sx={{ color: '#0074FF' }} />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant='body2' fontWeight={500} color={'#0074FF'} onClick={() => push(sm.catalogue.products.add.url)}> Create New Listing</Typography>
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon sx={{ pt: 0.5 }}>
                        <CloudIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant='body2' fontWeight={500} color={'#0074FF'}> Import from XLS </Typography>
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}
