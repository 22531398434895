import { SearchTextField, RadioButtonGroup } from '@/components';
import { GeneralObject, ProcurementModelDetails } from '@/models';
import {
  useProcurementStore,
  useSellerProcurementStore,
  useUserData,
} from '@/zustand';
import { Add, Close, InfoOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Grid,
  Stack,
  Typography,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import { Loading, StyledPopper } from 'src/ui/components/shared';
import {
  createProjectSchema,
  createRequisitionSchema,
} from '../../requisition/requisition-popup/validation-schema';
import usePopup from 'src/logic/hooks/use-popup';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';

type EditProjectProps = {
  formModel: ProcurementModelDetails;
  updateProject?: (project: GeneralObject) => void;
  isTextualEdit?: boolean;
  sx: any;
  projectId?: number;
};

const containerStyles: React.CSSProperties = {
  width: '300px',
  boxShadow: '0px 8px 32px 0px #EBEBEB',
};

export const EditProject: FC<EditProjectProps> = ({
  formModel,
  updateProject,
  isTextualEdit = false,
  sx = {},
  projectId,
}) => {
  const editProjectPopper = usePopup();
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const { fetchProjects } = useProcurementStore();
  const sellerData = useSellerProcurementStore();

  const projectsIsFetching = useLoadingState();
  React.useEffect(() => {
    if (editProjectPopper.isOpen) {
      projectsIsFetching.startLoading();
      useUserData.getState().isSeller()
        ? sellerData.fetchProjects(projectsIsFetching.finishLoading)
        : fetchProjects(projectsIsFetching.finishLoading);
    }
  }, [editProjectPopper.isOpen]);

  return (
    <Grid item md={1} sx={{ ml: -7, ...sx }}>
      <div ref={anchorRef}>
        {isTextualEdit ? (
          <Button
            type='button'
            sx={{ ml: 1, color: '#13628C', fontWeight: 500, fontSize: '12px' }}
            endIcon={<KeyboardArrowDownIcon sx={{ ml: -0.5, pb: 0.3 }} />}
            onClick={editProjectPopper.handleOpen}
          >
            {t('buttons.edit')}
          </Button>
        ) : (
          <IconButton
            sx={{ ml: 1, color: '#13628C', fontWeight: 500 }}
            onClick={editProjectPopper.handleOpen}
          >
            <KeyboardArrowDownIcon sx={{ fontWeight: 500, fontSize: '18px' }} />
          </IconButton>
        )}
      </div>

      <StyledPopper
        open={editProjectPopper.isOpen}
        anchorEl={anchorRef.current}
        handleClose={editProjectPopper.handleClose}
      >
        <div style={containerStyles}>
          {projectsIsFetching.isLoading ? (
            <div
              style={{
                minHeight: '10rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loading fullHeight={false} />
            </div>
          ) : (
            <EditProjectPopupContent
              formModel={formModel}
              closePopup={editProjectPopper.handleClose}
              changeProject={updateProject}
              theProjectId={projectId}
              projectsIsFetching={projectsIsFetching.isLoading}
            />
          )}
        </div>
      </StyledPopper>
    </Grid>
  );
};

const EditProjectPopupContent: FC<{
  formModel: ProcurementModelDetails;
  closePopup: () => void;
  changeProject?: (project: GeneralObject) => void;
  theProjectId?: number;
}> = ({ formModel, closePopup, changeProject, theProjectId }) => {
  const [searchText, setsearchText] = useState('');

  const changeLoading = useLoadingState();
  const [showCreateProject, setshowCreateProject] = useState<boolean>(false);
  const toggleCreateProject = () => setshowCreateProject(v => !v);

  const { projects, addProject, updateProject } = useProcurementStore();
  const sellerData = useSellerProcurementStore();
  const defaultProjectId = projects?.[0]?.id ?? '';
  const handleUpdateProject = values => {
    changeLoading.startLoading();
    updateProject(
      formModel.id,
      formModel.form_type,
      showCreateProject ? null : values.projectId,
      values.projectName,
      proj => {
        if (values.projectName) {
          useUserData.getState().isSeller()
            ? sellerData.addProject(proj)
            : addProject(proj);
        }
        changeProject?.(proj);
        changeLoading.finishLoading();
        closePopup();
      }
    );
  };
  const initialValues = {
    // projectId: formModel.project.id ?? defaultProjectId,
    projectId: theProjectId ?? defaultProjectId,
    projectName: '',
  };

  const projectsData = useUserData.getState().isSeller()
    ? sellerData.projects
    : projects;

  return (
    <Stack
      width='100%'
      sx={{ alignItems: 'center', textAlign: 'center' }}
      px={2}
      py={3.5}
    >
      <Stack gap={2} width='100%'>
        <Formik
          initialValues={initialValues}
          onSubmit={handleUpdateProject}
          validationSchema={
            showCreateProject ? createProjectSchema : createRequisitionSchema
          }
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              {showCreateProject ? (
                <>
                  <TextField
                    autoFocus
                    placeholder={'Create New Project'}
                    onChange={e =>
                      formikProps.setFieldValue('projectName', e.target.value)
                    }
                    value={formikProps.values.projectName}
                    InputProps={{
                      endAdornment: (
                        <Close
                          onClick={toggleCreateProject}
                          sx={{
                            color: '#8B8D98',
                            fontSize: '16px',
                            cursor: 'pointer',
                          }}
                        />
                      ),
                      sx: {
                        bgcolor: 'secondary.contrastText',
                        width: '100%',
                        height: '100%',
                        borderColor: 'divider',
                        borderRadius: '6px',
                      },
                    }}
                    sx={{
                      flex: 1,
                      height: '100%',
                      width: '100%',
                      textOverflow: 'ellipsis !important',
                    }}
                  />
                </>
              ) : (
                <>
                  <Box width={'100%'}>
                    <SearchTextField
                      translateKey='search_projects'
                      value={searchText}
                      onChange={e => setsearchText(e.target.value)}
                    />
                  </Box>
                  <Box my={1} maxHeight='200px' className='styled-scrollbar' style={{ overflow: 'auto' }}>
                    <Stack pl={1} className='styled-scrollbar'>
                      <RadioButtonGroup
                        data={
                          projectsData?.filter(project =>
                            project.name
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          ) ?? []
                        }
                        handleChange={(id: string) =>
                          formikProps.setFieldValue('projectId', id)
                        }
                        value={formikProps.values.projectId}
                        getSubTitle={() => {
                          return '';
                        }}
                      />
                    </Stack>
                  </Box>

                  <Divider sx={{ my: 1, width: '70%', marginInline: 'auto' }} />
                  <div style={{ display: 'flex' }}>
                    <Button
                      sx={{ color: '#3A3E50' }}
                      startIcon={<Add sx={{ color: '#13628C' }} />}
                      type='button'
                      onClick={toggleCreateProject}
                    >
                      Create New Project
                    </Button>
                  </div>
                </>
              )}

              <Typography
                px={1}
                pt={3}
                variant='subtitle2'
                color='text.third'
                lineHeight={1.3}
              >
                <InfoOutlined sx={{ fontSize: '13px' }} /> {t('texts.edit_project')}
              </Typography>
              <LoadingButton
                sx={{ mt: 2 }}
                type='submit'
                fullWidth
                variant='contained'
                loading={changeLoading.isLoading}
                disabled={showCreateProject && !formikProps.values.projectName}
              >
                {t('buttons.save')}
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Stack>
    </Stack>
  );
};
