import { Unpublished } from '@mui/icons-material';

export enum CommentLabel {
    REJECTED = "REJECTED",
    REJECTED_ALL = "REJECTED_ALL",
    APPROVED_ALL = "APPROVED_ALL"
}

export const commentsLabelProperties = {
    DANGER: { textColor: 'info.dark' },
    SUCCESS: { textColor: 'success.dark' },
    WARNING: { textColor: 'error.main' },
    INFO: { textColor: 'primary.main' }
}
export const enum UserPortalPermission {
    approver = "approver",
    owner = "owner"
}
export enum procurementStatus {
    DRAFT = "DRAFT",
    PENDING_APPROVAL = "PENDING_APPROVAL",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    MIXED = "MIXED",
    PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
    PARTIALLY_APPROVED_BY_ME = "PARTIALLY_APPROVED_BY_ME",
    PENDING_MY_APPROVAL = "PENDING_MY_APPROVAL",
    APPROVED_BY_ME = "APPROVED_BY_ME",
    REJECTED_BY_ME = "REJECTED_BY_ME",
    CONVERTED_TO_RFQ = "CONVERTED_TO_RFQ",
    SENT_FOR_APPROVAL = "SENT_FOR_APPROVAL",
    PENDING = "PENDING",
    CANCELED_RFQ = "CANCELED_RFQ",
    QUOTE_GENERATED = "QUOTE_GENERATED",
    SENT_TO_SELLERS = "SENT_TO_SELLERS",
    SENT_TO_SELLER = "SENT_TO_SELLER",
    SENT_TO_BUYER = "SENT_TO_BUYER",
    EDITING = "EDITING",
    PARTIALLY_SENT_TO_SELLERS = "PARTIALLY_SENT_TO_SELLERS",
    CANCELED = "CANCELED",
    ALL = "ALL",
    QUOTE_RECEIVED = "QUOTE_RECEIVED",
    PURCHASE_ORDER_GENERATED = "PURCHASE_ORDER_GENERATED",
    PO_GENERATED = "PO_GENERATED",
    RFQ_RECEIVED = "RFQ_RECEIVED",
    DECLINED = "DECLINED",
    PO_RECEIVED = "PO_RECEIVED",
    CONFIRMED = "CONFIRMED",
    RECALLED = "CANCELED",
    PUBLISHED = "PUBLISHED",
    READY = "READY",
    REQUESTED = "REQUESTED",
    PARTIALLY_CONFIRMED = "PARTIALLY_CONFIRMED",
    PARTIALLY_DECLINED = "PARTIALLY_DECLINED",
    QUOTES_RECEIVED = "QUOTES_RECEIVED",
    PARTIALLY_QUOTES_RECEIVED = "PARTIALLY_QUOTES_RECEIVED",
    QUOTE_EXPIRED = "QUOTE_EXPIRED",
    RFQ_EXPIRED = "RFQ_EXPIRED",
    REVOKED = "REVOKED",
    MULTI_POS_GENERATED = "MULTI_POS_GENERATED",
    UNPUBLISHED = "UNPUBLISHED",
    UNLINKED = "UNLINKED"
}
export enum ProcurementPopupStatus {
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    APPROVE_ALL = "APPROVE_ALL",
    REJECT_ALL = "REJECT_ALL",
    SENT_TO_SELLERS = "SENT_TO_SELLERS",
    SENT_TO_SELLER = "SENT_TO_SELLER",
    CANCEL_BUNDLE = 'CANCEL_BUNDLE',
    SENT_TO_BUYER = "SENT_TO_BUYER",
    SEND_FOR_APPROVAL = "SEND_FOR_APPROVAL",
    CANCEL_APPROVAL = "CANCEL_APPROVAL",
    CANCEL = "CANCEL",
    WARNING = "WARNING",
    DECLINE = "DECLINE",
    CONFIRM = "CONFIRM",
    RECALL_Bundle = "RECALL_Bundle",
    RECALL = "RECALL"
}
export enum ProcurementForms {
    REQUISITION = "Requisition",
    BUNDLE_RFQ = "Bundle RFQ",
    RFQ = "RFQ",
    BUNDLE_PO = "Bundle PO",
    PO = "PO",
    QUOTE = "Quote",
}

export enum ProcurementButtons {
    SAVE_REQUISITION_UPDATES = "SAVE_REQUISITION_UPDATES",

    GENERATE_RFQ = "GENERATE_RFQ",

    SEND_SINGLE_RFQ_FOR_APPROVAL = "SEND_SINGLE_RFQ_FOR_APPROVAL",
    CANCEL_SINGLE_RFQ_APPROVAL = "CANCEL_SINGLE_RFQ_APPROVAL",
    REJECT_SINGLE_RFQ = "REJECT_SINGLE_RFQ",
    APPROVE_SINGLE_RFQ = "APPROVE_SINGLE_RFQ",
    SEND_SINGLE_RFQ_TO_SELLER = "SEND_SINGLE_RFQ_TO_SELLER",
    RECALL_SINGLE_RFQ = "RECALL_SINGLE_RFQ",
    DECLINE_RFQ_BY_SELLER = "DECLINE_RFQ_BY_SELLER",

    SEND_BUNDLE_RFQ_FOR_APPROVAL = "SEND_BUNDLE_RFQ_FOR_APPROVAL",
    CANCEL_BUNDLE_RFQ_APPROVAL = "CANCEL_BUNDLE_RFQ_APPROVAL",
    APPROVE_INDIVIDUAL_RFQ = "APPROVE_INDIVIDUAL_RFQ",
    REJECT_INDIVIDUAL_RFQ = "REJECT_INDIVIDUAL_RFQ",
    APPROVE_ALL_RFQs = "APPROVE_ALL_RFQs",
    REJECT_ALL_RFQs = "REJECT_ALL_RFQs",
    SEND_BUNDLE_RFQ_TO_SELLER = "SEND_BUNDLE_RFQ_TO_SELLER",
    SEND_INDIVIDUAL_RFQ_TO_SELLER = "SEND_INDIVIDUAL_RFQ_TO_SELLER",
    RECALL_BUNDLE_RFQ = "RECALL_BUNDLE_RFQ",
    RECALL_INDIVIDUAL_RFQ = "RECALL_INDIVIDUAL_RFQ",

    GENERATE_QUOTE = "GENERATE_QUOTE",
    REJECT_QUOTE = "REJECT_QUOTE",
    APPROVE_QUOTE = "APPROVE_QUOTE",
    SEND_QUOTE_FOR_APPROVAL = "SEND_QUOTE_FOR_APPROVAL",
    CANCEL_QUOTE_APPROVAL = "CANCEL_QUOTE_APPROVAL",
    SEND_QUOTE_TO_BUYER = "SEND_QUOTE_TO_BUYER",
    CANCEL_SEND_QUOTE_TO_BUYER = "CANCEL_SEND_QUOTE_TO_BUYER",
    DECLINE_QUOTE_BY_BUYER = "DECLINE_QUOTE_BY_BUYER",

    GENERATE_PO_BY_BUYER = "GENERATE_PO_BY_BUYER",
    SAVE_PO_UPDATES = "SAVE_PO_UPDATES",
    GENERATE_PO_FROM_CT = "GENERATE_PO_FROM_CT",

    SEND_SINGLE_PO_FOR_APPROVAL = "SEND_SINGLE_PO_FOR_APPROVAL",
    CANCEL_SINGLE_PO_APPROVAL = "CANCEL_SINGLE_PO_APPROVAL",
    REJECT_SINGLE_PO = "REJECT_SINGLE_PO",
    APPROVE_SINGLE_PO = "APPROVE_SINGLE_PO",
    SEND_SINGLE_PO_TO_SELLER = "SEND_SINGLE_PO_TO_SELLER",
    RECALL_SINGLE_PO = "RECALL_SINGLE_PO",
    CANCEL_SINGLE_PO = "CANCEL_SINGLE_PO",


    SEND_BUNDLE_PO_FOR_APPROVAL = "SEND_BUNDLE_PO_FOR_APPROVAL",
    CANCEL_BUNDLE_PO_APPROVAL = "CANCEL_BUNDLE_PO_APPROVAL",
    APPROVE_INDIVIDUAL_PO = "APPROVE_INDIVIDUAL_PO",
    REJECT_INDIVIDUAL_PO = "REJECT_INDIVIDUAL_PO",
    APPROVE_ALL_POs = "APPROVE_ALL_POs",
    REJECT_ALL_POs = "REJECT_ALL_POs",
    SEND_BUNDLE_PO_TO_SELLER = "SEND_BUNDLE_PO_TO_SELLER",
    SEND_INDIVIDUAL_PO_TO_SELLER = "SEND_INDIVIDUAL_PO_TO_SELLER",
    RECALL_BUNDLE_PO = "RECALL_BUNDLE_PO",
    RECALL_INDIVIDUAL_PO = "RECALL_INDIVIDUAL_PO",
    CANCEL_BUNDLE_PO = "CANCEL_BUNDLE_PO",
    CANCEL_INDIVIDUAL_PO = "CANCEL_INDIVIDUAL_PO",

    DECLINE_PO_BY_SELLER = "DECLINE_PO_BY_SELLER",
    CONFIRM_PO_BY_SELLER = "CONFIRM_PO_BY_SELLER",

    REQUEST_EXTENSION = "REQUEST_EXTENSION",
    SAVE_EXPIRY_DATE = "SAVE_EXPIRY_DATE",
    DECLINE_REQUEST_EXTENSION = "DECLINE_REQUEST_EXTENSION",
    SAVE_NEW_COMPANY = "SAVE_NEW_COMPANY",

    LINK_ALL_RESULTS = "LINK_ALL_RESULTS",
    UNLINK_ALL_RESULTS = "UNLINK_ALL_RESULTS",
    LINK_PRODUCT = "LINK_PRODUCT"
}

export enum RadioButtonStatus {
    ALREADY_EXISTS = "ALREADY_EXISTS",
    ACTIVE = "ACTIVE"
}

export * from './bundle-rfq';
export * from './product-manager';
export * from './quote';
export * from './request-for-quote';
export * from './requisition';

