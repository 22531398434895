import { SvgIcon } from "@mui/material";

export const CloudIcon = () => (
    <SvgIcon width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_40851_272532" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
            <rect y="0.0742188" width="16" height="16" fill="#0074FF" />
        </mask>
        <g mask="url(#mask0_40851_272532)">
            <path d="M4.32682 13.401C3.31571 13.401 2.45182 13.051 1.73516 12.351C1.01849 11.651 0.660156 10.7955 0.660156 9.78438C0.660156 8.91771 0.921267 8.14549 1.44349 7.46771C1.96571 6.78993 2.64905 6.3566 3.49349 6.16771C3.77127 5.14549 4.32682 4.31771 5.16016 3.68438C5.99349 3.05104 6.93794 2.73438 7.99349 2.73438C9.29349 2.73438 10.3963 3.18715 11.3018 4.09271C12.2074 4.99826 12.6602 6.10104 12.6602 7.40104C13.4268 7.48993 14.0629 7.82049 14.5685 8.39271C15.0741 8.96493 15.3268 9.63438 15.3268 10.401C15.3268 11.2344 15.0352 11.9427 14.4518 12.526C13.8685 13.1094 13.1602 13.401 12.3268 13.401H8.66016C8.29349 13.401 7.9796 13.2705 7.71849 13.0094C7.45738 12.7483 7.32683 12.4344 7.32683 12.0677V8.63438L6.26016 9.66771L5.32682 8.73438L7.99349 6.06771L10.6602 8.73438L9.72683 9.66771L8.66016 8.63438V12.0677H12.3268C12.7935 12.0677 13.1879 11.9066 13.5102 11.5844C13.8324 11.2622 13.9935 10.8677 13.9935 10.401C13.9935 9.93438 13.8324 9.53993 13.5102 9.21771C13.1879 8.89549 12.7935 8.73438 12.3268 8.73438H11.3268V7.40104C11.3268 6.47882 11.0018 5.69271 10.3518 5.04271C9.70183 4.39271 8.91572 4.06771 7.99349 4.06771C7.07127 4.06771 6.28516 4.39271 5.63516 5.04271C4.98516 5.69271 4.66016 6.47882 4.66016 7.40104H4.32682C3.68238 7.40104 3.13238 7.62882 2.67682 8.08438C2.22127 8.53993 1.99349 9.08993 1.99349 9.73438C1.99349 10.3788 2.22127 10.9288 2.67682 11.3844C3.13238 11.8399 3.68238 12.0677 4.32682 12.0677H5.99349V13.401H4.32682Z" fill="#0074FF" />
        </g>
    </SvgIcon>
);
