import { Check } from "@mui/icons-material";
import { Button } from "@mui/material";
import { t } from "i18next";
import { FC, useState } from "react";

interface props {
    handleClickHiddenButton: () => void
    mainButtonTitleKey: string
    hiddenButtonTitleKey: string
}

export const TwoButtonsWithHoverToggle: FC<props> = (props) => {
    const [showHiddenButton, setShowHiddenButton] = useState(false);
    return (
        <>
            {!showHiddenButton ?
                <Button
                    {...props}
                    onMouseOver={() => setShowHiddenButton(true)}
                    fullWidth
                    variant="outlined_background"
                    startIcon={<Check />}
                    sx={{lineHeight:1.2}}
                    >
                    {t(`buttons.${props.mainButtonTitleKey}`)}
                </Button>
                :
                <Button
                    {...props}
                    onClick={props.handleClickHiddenButton}
                    onMouseOut={() => setShowHiddenButton(false)}
                    fullWidth
                    variant="contained"
                    color="error"
                    sx={{lineHeight:1.2}}
                >
                    {t(`buttons.${props.hiddenButtonTitleKey}`)}
                </Button>
            }
        </>
    )
}