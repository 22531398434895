import { Loading, RadioButtonGroup, SearchTextField } from '@/components';
import { productManagerEnv, ProductManagerPopupStatus, RadioButtonStatus } from '@/config';
import { useDebounce } from '@/hooks';
import { ProcurementModelDetails, Product } from '@/models';
import { NotifySuccess } from '@/services';
import { useProcurementStore } from '@/zustand';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { t } from 'i18next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import createPoInitialValues from '../create-with-product/initialValues';
import initialValues from './initialValues';
import { useGetRelatedPurchaseOrders } from './po-query';
import validationSchema from './validationSchema';

export const SelectPurchaseOrderPoppup: FC<{
  selectedSeller?: string;
  product: Product;
  popupState: number;
  changePopupState: (val: number) => void;
  sellerId?: string;
}> = ({ selectedSeller, product, popupState, changePopupState, sellerId }) => {
  const router = useRouter();
  const { purchaseOrderRelatedData } = useProcurementStore();
  const { selectForm } = useProcurementStore(state => state.productFormManagerProperties.actions);
  useEffect(() => {
    changePopupState(0);
  }, []);

  const currentValidationSchema = validationSchema[popupState];
  const getSellerFiltration = (form: ProcurementModelDetails) => {
    router.query['seller-id'] = form?.seller_details?.id;
    router.push(router);
  };
  const handleSelectPO = (values, actions) => {
    selectForm(
      { ...values, product: { ...product, price: values.price || product.price } },
      'purchaseOrderBuyerDetailsSliceActions',
      productManagerEnv.purchase_order,
      purchaseOrderRelatedData?.purchase_orders,
      (form: ProcurementModelDetails) => {
        // addProduct({ ...product, price: values.price });
        getSellerFiltration(form);
      }
    );
  };
  return (
    <Stack sx={{ alignItems: 'center', textAlign: 'center', m: 1 }}>
      <Stack>
        {product && (
          <Formik
            initialValues={initialValues(product.id, product.quantity)}
            validationSchema={currentValidationSchema}
            onSubmit={handleSelectPO}
            isolatedFields={true}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <Typography color='primary' variant='overline' fontWeight='700' mt={1}>
                  {t('buttons.add_to_PO')}
                </Typography>
                {popupState === 0 ? (
                  <SelectPo
                    selectedSeller={selectedSeller}
                    changePopupState={changePopupState}
                    disabledButton={!(formikProps.isValid && formikProps.dirty)}
                    product={product}
                    sellerId={sellerId}
                  />
                ) : (
                  <></>
                )}
              </form>
            )}
          </Formik>
        )}
      </Stack>
    </Stack>
  );
};

const SelectPo: FC<{
  changePopupState: (val: number) => void;
  disabledButton: boolean;
  selectedSeller?: string;
  product: Product;
  sellerId?: string;
}> = ({ selectedSeller, changePopupState, disabledButton, product, sellerId }) => {
  const { submitForm } = useFormikContext();
  const handleSelectPurchaseOrder = () => submitForm();
  const { t } = useTranslation();

  const [searchText, setsearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  useDebounce(() => setDebouncedSearchText(searchText), 700, [searchText]);
  const { values, setFieldValue } = useFormikContext();
  const { actions } = useProcurementStore(state => state.productFormManagerProperties);
  const { purchaseOrderBuyerDetailsSliceActions } = useProcurementStore();

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useGetRelatedPurchaseOrders({
    product_id: product?.id,
    seller_id: sellerId,
    search: debouncedSearchText,
  });

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.();
      }
    },
  });

  const purchaseOrders = React.useMemo(() => {
    return data?.pages?.flatMap(page => page.data) ?? [];
  }, [data]);

  const handleChangePurchaseOrder = (id: string) => {
    setFieldValue('purchase_order_id', id);
    const selectedPoObject = purchaseOrders.find(form => form.purchase_order.id === parseInt(id));
    setFieldValue('price', selectedPoObject?.product_price ?? '');
  };

  const isCreatingPo = useLoadingState();
  const handleCreatePO = () => {
    isCreatingPo.startLoading();
    const values = createPoInitialValues(product.id, product.quantity, selectedSeller);
    purchaseOrderBuyerDetailsSliceActions.create(
      {
        projectId: '',
        projectName: '',
        sellerId: values.seller_id,
      },
      newPurchaseOrder => {
        actions.setDetails(newPurchaseOrder, productManagerEnv.purchase_order);
        actions.addProduct(product);
        // NotifySuccess('Purchase Order Created');
        NotifySuccess(t('notifications.create_form', { formName: newPurchaseOrder.name }));
        actions.setProductManagerPopupStatus(null);
      }
    );
  };

  return (
    <Stack>
      <Typography variant='body2' color='text.disabled'>
        {t('texts.select_po')}
      </Typography>
      <Box my={2} height={40}>
        <SearchTextField translateKey='search_pos' value={searchText} onChange={e => setsearchText(e.target.value)} />
      </Box>
      <Stack mb={2} height='220px' ref={containerRef}>
        {isLoading ? (
          <Loading fullHeight={false} />
        ) : (
          <Stack pl={1}>
            <RadioButtonGroup
              data={purchaseOrders?.map(form => {
                return { ...form, ...form.purchase_order };
              })}
              handleChange={handleChangePurchaseOrder}
              value={values.purchase_order_id}
              getSubTitle={(purchaseOrder: any) => {
                return purchaseOrder?.sender_project?.name + ' ; ' + purchaseOrder.seller_details.name ?? '';
              }}
              isItemDisabled={(purchaseOrder: any) => {
                return purchaseOrder.active_status === RadioButtonStatus.ALREADY_EXISTS;
              }}
              disableTypography={t('texts.' + RadioButtonStatus.ALREADY_EXISTS)}
              disableSorting
            />
            {hasNextPage && (
              <div
                style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
                ref={observerRef}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.5rem' }}>
                  <CircularProgress size={'1rem'} />
                  <Typography color='#8B8D98' fontWeight={400} fontSize={13}>
                    Loading Purchase Orders...
                  </Typography>
                </div>
              </div>
            )}
          </Stack>
        )}
      </Stack>
      <Button
        variant='contained'
        onClick={handleSelectPurchaseOrder}
        disabled={disabledButton || isCreatingPo.isLoading}
      >
        {t('buttons.select')}
      </Button>
      {t('texts.or')}
      <LoadingButton
        loading={isCreatingPo.isLoading}
        variant='outlined'
        onClick={() => {
          if (selectedSeller) {
            handleCreatePO();
          } else {
            actions.setProductManagerPopupStatus(ProductManagerPopupStatus.CREATE_PO);
          }
        }}
      >
        {t('popups.titles.create_new_purchase_order')}
      </LoadingButton>
    </Stack>
  );
};
