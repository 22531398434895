import { procurementStatus } from "@/config";
import {
    ColumnFormat, ColumnSort,
    ColumnType, TableColumnMapper,
    TableGroup,
    TableMapper,
    TableRow,
    TableRowAction,
    TableType,
    TabMapper
} from "@/models";
import { NotifySuccess, useCreateEmptyPO, useIndexBuyerPurchaseOrders } from "@/services";
import { useUserData } from "@/zustand";
import { t } from "i18next";
import produce from "immer";
import { toast } from "react-toastify";
import { StateCreator } from "zustand";
import { buildTableGroupModel } from "../../../services/tables";

export interface BuyerPurchaseOrderTableSlice {
    buyerPurchaseOrderTableSliceProperties: {
        data: {
            tableGroup: TableGroup | null,
            isLoading: boolean,
            canCreate: boolean
        },
        actions: {
            loadData: () => void,
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => void;
            create: (values: { projectId: string, projectName: string, sellerId: string }, navigateToPoPage: (id: string, data?: any) => void, onError?: () => void) => void
        }
    },
}

export const createBuyerPurchaseOrderTableSlice: StateCreator<BuyerPurchaseOrderTableSlice> = (set, get, api) => ({
    buyerPurchaseOrderTableSliceProperties: {
        data: {
            tableGroup: null,
            isLoading: true,
            canCreate: false
        },
        actions: {
            loadData: () => {
                set(produce(draftState => {
                    draftState.buyerPurchaseOrderTableSliceProperties.data.isLoading = true
                }))

                useIndexBuyerPurchaseOrders().then(data => {
                    const userId = useUserData.getState().userInfo?.id
                    const canCreatePO = useUserData.getState().canCreatePO
                    const hasApproverRole = useUserData.getState().canApprovePurchaseOrder
                    const tableGroup = buildTableGroupModel(
                        data.data, poTableMapper(), poColumnsMapper(), userId, hasApproverRole, 'id', 'owner.id'
                    )
                    set(produce(draftState => {
                        draftState.buyerPurchaseOrderTableSliceProperties.data.isLoading = false
                        draftState.buyerPurchaseOrderTableSliceProperties.data.tableGroup = tableGroup
                        draftState.buyerPurchaseOrderTableSliceProperties.data.canCreate = canCreatePO
                    }))
                })
            },
            create: (values: { projectId: string, projectName: string, sellerId: string }, navigateToPoPage: (id: string, data?: any) => void, onError?: () => void) => {
                useCreateEmptyPO(values.projectId, values.projectName, values.sellerId).then(data => {
                    navigateToPoPage(data.data.id, data.data)
                    // NotifySuccess(t('notifications.purchase_order_created'))
                    NotifySuccess(t('notifications.create_form', { formName: data.data.name}))
                }).catch(() => {
                    onError?.();
                })
            },
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => {

            }
        }
    }
})

export const poTableMapper = () => {
    return [
        new TableMapper(TableType.OTHER, 'Other PO', [
            new TabMapper('all', [procurementStatus.ALL]),
            new TabMapper('DRAFT', [procurementStatus.DRAFT]),
            new TabMapper('SENT_FOR_APPROVAL', [procurementStatus.PENDING_APPROVAL, procurementStatus.REJECTED, procurementStatus.APPROVED]),
            new TabMapper('SENT_TO_SELLER', [procurementStatus.SENT_TO_SELLER]),
        ]),
        new TableMapper(TableType.MINE, 'My PO', [
            new TabMapper('all', [procurementStatus.ALL], true),
            new TabMapper('DRAFT', [procurementStatus.DRAFT], true),
            new TabMapper('SENT_FOR_APPROVAL', [procurementStatus.PENDING_APPROVAL, procurementStatus.REJECTED, procurementStatus.APPROVED], true),
            new TabMapper('SENT_TO_SELLER', [procurementStatus.SENT_TO_SELLER], true),
        ]),
        new TableMapper(TableType.MY_APPROVAL, 'My Approvals', [
            new TabMapper('all', [procurementStatus.ALL]),
            new TabMapper('PENDING', [procurementStatus.PENDING_MY_APPROVAL]),
            new TabMapper('APPROVED', [procurementStatus.APPROVED_BY_ME]),
            new TabMapper('REJECTED', [procurementStatus.REJECTED_BY_ME]),
        ])
    ];
}

export const poColumnsMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'po', ColumnType.STRING, ColumnFormat.URL,
        new TableColumnMapper('purchase_order_bundle.name', 'project', ColumnType.STRING)
    );

    const dateColumn = new TableColumnMapper('created_at', 'date', ColumnType.DATE)
    dateColumn.columnSort = new ColumnSort(true, true)

    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'products', ColumnType.NUMBER)

    const detailsColumn = new TableColumnMapper('rfq.name', 'details', ColumnType.STRING, ColumnFormat.SUBTITLE,
        new TableColumnMapper('quote.name', 'project', ColumnType.STRING)
    );

    const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.STRING)

    const sellerColumn = new TableColumnMapper('seller_details.name', 'owner', ColumnType.STRING)

    return [
        new TableColumnMapper('id', 'id', ColumnType.NUMBER, null, null, true),
        nameColumn,
        detailsColumn,
        dateColumn,
        numberOfProductsColumn,
        ownerColumn,
        sellerColumn,
        new TableColumnMapper('total_value', 'value', ColumnType.STRING),
        new TableColumnMapper('status', 'status', ColumnType.STRING, ColumnFormat.CHIP, null, true),
    ];
}
